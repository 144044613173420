import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addCategoryAction, editCategoryAction } from "../../Action/Category";
import { uploadFileAction } from "../../Action/uploadFile";
import { backendUrl } from "../../constants";
import { useLocation, useNavigate } from "react-router";
import { RoutesPaths } from "../../Router/constant";

export default function EditCategory() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    reset,
  } = useForm({
    mode: "onChange", // Enable validation on change
    defaultValues: {
      status: "active",
    },
  });

  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { category } = location.state || {};
  const isActive = category?.isActive;

  useEffect(() => {
    if (isActive !== undefined) {
      setValue("status", isActive ? "active" : "deactive");
    }

    setValue("title", category?.title || "");
    setValue("description", category?.description || "");

    if (category?.image) {
      setImagePreviewUrl(category.image);
    } else {
      setImagePreviewUrl("");
    }
  }, [isActive, category, setValue]);

  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(uploadFileAction({ base64: reader.result }))
          .then((response) => {
            setImagePreviewUrl(response?.data?.fileName);
          })
          .catch((error) => {
            console.error(error); // Handle error response
          });
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setImagePreviewUrl("");
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    const isActive = data.status === "active";
    const dataToSave = {
      title: data.title,
      image: imagePreviewUrl || "",
      description: data.description,
      isActive,
      _id: category && category._id ? category._id : "",
    };

    dispatch(
      category ? editCategoryAction(dataToSave) : addCategoryAction(dataToSave)
    )
      .then(() => {
        navigate(RoutesPaths.CATEGORY);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
      <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="card">
              <div className="card-header">
                <div className="card-title fs-3 fw-bold">
                  {category ? "Edit/" : "Add/"}Category
                </div>
              </div>
              <form
                id="kt_project_settings_form"
                className="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="card-body p-9">
                  <div className="row mb-5">
                    <div className="col-xl-3">
                      <div className="fs-6 fw-semibold mt-2 mb-3">
                        Category Image
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div
                        className="image-input image-input-outline"
                        data-kt-image-input="true"
                      >
                        <div
                          className="image-input-wrapper w-125px h-125px bgi-position-center"
                          style={{
                            backgroundImage: `url("${
                              imagePreviewUrl
                                ? `${backendUrl + imagePreviewUrl}`
                                : "assets/media/svg/avatars/blank.svg"
                            }")`,
                          }}
                        />
                        <label
                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                          data-kt-image-input-action="change"
                          data-bs-toggle="tooltip"
                          title="Change avatar"
                        >
                          <i className="ki-outline ki-pencil fs-7" />
                          <input
                            type="file"
                            name="image"
                            accept=".png, .jpg, .jpeg"
                            onChange={handleImageChange}
                          />
                          <input
                            type="hidden"
                            name="image"
                            {...register("image")}
                          />
                        </label>
                        <span
                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                          data-kt-image-input-action="cancel"
                          data-bs-toggle="tooltip"
                          title="Cancel avatar"
                        >
                          <i className="ki-outline ki-cross fs-2" />
                        </span>
                        {imagePreviewUrl && (
                          <span
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                            data-kt-image-input-action="remove"
                            data-bs-toggle="tooltip"
                            title="Remove avatar"
                            onClick={removeImage}
                          >
                            <i className="ki-outline ki-cross fs-2" />
                          </span>
                        )}
                      </div>
                      <div className="form-text">
                        Allowed file types: png, jpg, jpeg.
                      </div>
                      {errors.image && (
                        <div className="text-danger">
                          {errors.image.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-8">
                    <div className="col-xl-3">
                      <div className="fs-6 fw-semibold mt-2 mb-3">Title</div>
                    </div>
                    <div className="col-xl-9 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        name="title"
                        placeholder="Enter title"
                        {...register("title", {
                          required: "Title is required.",
                        })}
                      />
                      {errors.title && (
                        <div className="text-danger">
                          {errors.title.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-8">
                    <div className="col-xl-3">
                      <div className="fs-6 fw-semibold mt-2 mb-3">
                        Description
                      </div>
                    </div>
                    <div className="col-xl-9 fv-row">
                      <textarea
                        rows="2"
                        className="form-control form-control-solid"
                        name="description"
                        placeholder="Description"
                        {...register("description", {
                          required: "Description is required.",
                        })}
                      />
                      {errors.description && (
                        <div className="text-danger">
                          {errors.description.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-8">
                    <div className="col-md-3">
                      <div className="fs-6 fw-semibold mt-2 mb-3">
                        Active Category
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="d-flex fw-semibold h-100 radio-group">
                        <div className="form-check form-check-custom form-check-solid me-9">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="active"
                            id="active"
                            {...register("status", {
                              required: "Status is required.",
                            })}
                            name="status"
                          />
                          <label
                            className="form-check-label ps-3 ms-0"
                            htmlFor="active"
                          >
                            Active
                          </label>
                        </div>
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="deactive"
                            id="deactive"
                            {...register("status", {
                              required: "Status is required.",
                            })}
                            name="status"
                          />
                          <label
                            className="form-check-label ps-3 ms-0"
                            htmlFor="deactive"
                          >
                            De-active
                          </label>
                        </div>
                      </div>
                      {errors.status && (
                        <div className="text-danger">
                          {errors.status.message}
                        </div>
                      )}
                    </div>
                    {/*end::Col*/}
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <button
                    type="reset"
                    className="btn btn-light btn-active-light-primary me-2"
                    onClick={() => reset()}
                  >
                    Discard
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting || !isValid}
                  >
                    {category ? "Save Changes" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
