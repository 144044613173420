import axionIns from "../axios";

const addServices = async (payload) => {
  return axionIns
    .post("admin/services", JSON.stringify(payload))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

const editService = async (payload) => {
  return axionIns
    .put("admin/services/" + payload._id, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

const deleteService = async (id) => {
  return axionIns
    .delete("admin/services/" + id)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

const getServices = async (page, limit, search) => {
  return axionIns
    .get(
      `admin/services?page=${page}&limit=${limit ? limit : 25}&search=${search}`
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  addServices,
  editService,
  deleteService,
  getServices,
};
