import axios from "axios";
import { backendUrl } from "./constants";

const axionIns = axios.create({
  baseURL: backendUrl+"api/",
  headers: { 
    "Content-Type": "application/json",
  },
});
// Set the AUTH token for any request
axionIns.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
  config.headers['token'] = token;
  return config;
});



export default axionIns;
