import axionIns from "../axios";

const getUser = async (page, limit, search) => {
  return axionIns
    .get(`admin/profile/users?page=${page}&limit=${limit}&search=${search}`)
    .then((response) => {
      return response.data;
    });
};

export default {
  getUser,
};
