import { NavLink } from "react-router-dom";
import { RoutesPaths } from "../Router/constant";

export default function Header() {
  return (
    <div id="kt_app_header" className="app-header">
      <div
        className="app-container container-fluid d-flex align-items-stretch flex-stack"
        id="kt_app_header_container"
      >
        <div
          className="d-flex align-items-center d-block d-lg-none ms-n3"
          title="Show sidebar menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px me-2"
            id="kt_app_sidebar_mobile_toggle"
          >
            <i className="ki-outline ki-abstract-14 fs-2" />
          </div>
          <a href="index.html">
            <img
              alt="Logo"
              src="assets/img/express-job-logo.svg"
              className="h-30px"
            />
          </a>
        </div>
        <div className="app-navbar flex-lg-grow-1" id="kt_app_header_navbar">
          <div className="app-navbar-item d-flex align-items-stretch flex-lg-grow-1 visibilityHide">
            <div
              id="kt_header_search"
              className="header-search d-flex align-items-center w-lg-200px"
              data-kt-search-keypress="true"
              data-kt-search-min-length={2}
              data-kt-search-enter="enter"
              data-kt-search-layout="menu"
              data-kt-search-responsive="true"
              data-kt-menu-trigger="auto"
              data-kt-menu-permanent="true"
              data-kt-menu-placement="bottom-start"
            >
              <div
                data-kt-search-element="toggle"
                className="search-toggle-mobile d-flex d-lg-none align-items-center"
              >
                <div className="d-flex">
                  <i className="ki-outline ki-magnifier fs-1" />
                </div>
              </div>
              <form
                data-kt-search-element="form"
                className="d-none d-lg-block w-100 position-relative mb-5 mb-lg-0"
                autoComplete="off"
              >
                <input type="hidden" />
                <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                <input
                  type="text"
                  className="search-input form-control form-control rounded-1 ps-13"
                  name="search"
                  defaultValue=""
                  placeholder="Search..."
                  data-kt-search-element="input"
                />
                <span
                  className="search-spinner position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5"
                  data-kt-search-element="spinner"
                >
                  <span className="spinner-border h-15px w-15px align-middle text-gray-500" />
                </span>
                <span
                  className="search-reset btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4"
                  data-kt-search-element="clear"
                >
                  <i className="ki-outline ki-cross fs-2 fs-lg-1 me-0" />
                </span>
              </form>
            </div>
          </div>

          <div className="app-navbar-item ms-1 ms-md-3">
            <div className="custom btn btn-icon btn-custom btn-color-gray-600 btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px position-relative">
              <i className="ki-outline ki-notification-on fs-1" />
              <span className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger w-15px h-15px ms-n4 mt-3">
                5
              </span>
            </div>
            <div className="modalData">
              <div className="row">
                <div className="col-md-6 notificationText">Notification</div>
                <div className="col-md-6 text-end viewAll">View All</div>
              </div>
              <div className="row notificationList">
                <div className="col-md-8">
                  <div className="leftSection">
                    <img
                      src="assets/media/avatars/300-6.jpg"
                      alt="Emma Smith"
                      className="customAvatar"
                    />
                    <div className="detailsSection">
                      <h5 className="authorName">Emma Smith</h5>
                      <h6 className="authorRole">Project Manager</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 timeSection">
                    <h6 className="authorRole">2 min ago</h6>
                </div>
              </div>
              <div className="row notificationList">
                <div className="col-md-8">
                  <div className="leftSection">
                    <img
                      src="assets/media/avatars/300-6.jpg"
                      alt="Emma Smith"
                      className="customAvatar"
                    />
                    <div className="detailsSection">
                      <h5 className="authorName">Emma Smith</h5>
                      <h6 className="authorRole">Project Manager</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 timeSection">
                    <h6 className="authorRole">2 min ago</h6>
                </div>
              </div>
              <div className="row notificationList">
                <div className="col-md-8">
                  <div className="leftSection">
                    <img
                      src="assets/media/avatars/300-6.jpg"
                      alt="Emma Smith"
                      className="customAvatar"
                    />
                    <div className="detailsSection">
                      <h5 className="authorName">Emma Smith</h5>
                      <h6 className="authorRole">Project Manager</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 timeSection">
                    <h6 className="authorRole">2 min ago</h6>
                </div>
              </div>
              <div className="row notificationList">
                <div className="col-md-8">
                  <div className="leftSection">
                    <img
                      src="assets/media/avatars/300-6.jpg"
                      alt="Emma Smith"
                      className="customAvatar"
                    />
                    <div className="detailsSection">
                      <h5 className="authorName">Emma Smith</h5>
                      <h6 className="authorRole">Project Manager</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 timeSection">
                    <h6 className="authorRole">2 min ago</h6>
                </div>
              </div>
            </div>
          </div>

          <div
            className="app-navbar-item ms-1 ms-md-3"
            id="kt_header_user_menu_toggle"
          >
            <NavLink to={RoutesPaths.PROFILE}>
              <div
                className="cursor-pointer symbol symbol-circle symbol-35px symbol-md-45px"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
              >
                <img src="assets/media/avatars/300-2.jpg" alt="user" />
              </div>
            </NavLink>
            
            <div
              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
              data-kt-menu="true"
            >
              <div className="menu-item px-3">
                <div className="menu-content d-flex align-items-center px-3">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-50px me-5">
                    <img alt="Logo" src="assets/media/avatars/300-2.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Username*/}
                  <div className="d-flex flex-column">
                    <div className="fw-bold d-flex align-items-center fs-5">
                      Max Smith
                      <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                        Pro
                      </span>
                    </div>
                    <a
                      href="#"
                      className="fw-semibold text-muted text-hover-primary fs-7"
                    >
                      max@kt.com
                    </a>
                  </div>
                  {/*end::Username*/}
                </div>
              </div>
              <div className="separator my-2" />
              <div className="menu-item px-5">
                <a href="account/overview.html" className="menu-link px-5">
                  My Profile
                </a>
              </div>
              <div className="menu-item px-5">
                <a href="apps/projects/list.html" className="menu-link px-5">
                  <span className="menu-text">My Projects</span>
                  <span className="menu-badge">
                    <span className="badge badge-light-danger badge-circle fw-bold fs-7">
                      3
                    </span>
                  </span>
                </a>
              </div>
              <div
                className="menu-item px-5"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="left-start"
                data-kt-menu-offset="-15px, 0"
              >
                <a href="#" className="menu-link px-5">
                  <span className="menu-title">My Subscription</span>
                  <span className="menu-arrow" />
                </a>
                <div className="menu-sub menu-sub-dropdown w-175px py-4">
                  <div className="menu-item px-3">
                    <a href="account/referrals.html" className="menu-link px-5">
                      Referrals
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="account/billing.html" className="menu-link px-5">
                      Billing
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a
                      href="account/statements.html"
                      className="menu-link px-5"
                    >
                      Payments
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a
                      href="account/statements.html"
                      className="menu-link d-flex flex-stack px-5"
                    >
                      Statements
                      <span
                        className="ms-2 lh-0"
                        data-bs-toggle="tooltip"
                        title="View your statements"
                      >
                        <i className="ki-outline ki-information-5 fs-5" />
                      </span>
                    </a>
                  </div>
                  <div className="separator my-2" />
                  <div className="menu-item px-3">
                    <div className="menu-content px-3">
                      <label className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input w-30px h-20px"
                          type="checkbox"
                          defaultValue={1}
                          defaultChecked="checked"
                          name="notifications"
                        />
                        <span className="form-check-label text-muted fs-7">
                          Notifications
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="menu-item px-5">
                <a href="account/statements.html" className="menu-link px-5">
                  My Statements
                </a>
              </div>
              <div className="separator my-2" />
              <div
                className="menu-item px-5"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="left-start"
                data-kt-menu-offset="-15px, 0"
              >
                <a href="#" className="menu-link px-5">
                  <span className="menu-title position-relative">
                    Mode
                    <span className="ms-5 position-absolute translate-middle-y top-50 end-0">
                      <i className="ki-outline ki-night-day theme-light-show fs-2" />
                      <i className="ki-outline ki-moon theme-dark-show fs-2" />
                    </span>
                  </span>
                </a>
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                  data-kt-menu="true"
                  data-kt-element="theme-mode-menu"
                >
                  <div className="menu-item px-3 my-0">
                    <a
                      href="#"
                      className="menu-link px-3 py-2"
                      data-kt-element="mode"
                      data-kt-value="light"
                    >
                      <span className="menu-icon" data-kt-element="icon">
                        <i className="ki-outline ki-night-day fs-2" />
                      </span>
                      <span className="menu-title">Light</span>
                    </a>
                  </div>
                  <div className="menu-item px-3 my-0">
                    <a
                      href="#"
                      className="menu-link px-3 py-2"
                      data-kt-element="mode"
                      data-kt-value="dark"
                    >
                      <span className="menu-icon" data-kt-element="icon">
                        <i className="ki-outline ki-moon fs-2" />
                      </span>
                      <span className="menu-title">Dark</span>
                    </a>
                  </div>
                  <div className="menu-item px-3 my-0">
                    <a
                      href="#"
                      className="menu-link px-3 py-2"
                      data-kt-element="mode"
                      data-kt-value="system"
                    >
                      <span className="menu-icon" data-kt-element="icon">
                        <i className="ki-outline ki-screen fs-2" />
                      </span>
                      <span className="menu-title">System</span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="menu-item px-5"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="left-start"
                data-kt-menu-offset="-15px, 0"
              >
                <a href="#" className="menu-link px-5">
                  <span className="menu-title position-relative">
                    Language
                    <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                      English
                      <img
                        className="w-15px h-15px rounded-1 ms-2"
                        src="assets/media/flags/united-states.svg"
                        alt=""
                      />
                    </span>
                  </span>
                </a>
                <div className="menu-sub menu-sub-dropdown w-175px py-4">
                  <div className="menu-item px-3">
                    <a
                      href="account/settings.html"
                      className="menu-link d-flex px-5 active"
                    >
                      <span className="symbol symbol-20px me-4">
                        <img
                          className="rounded-1"
                          src="assets/media/flags/united-states.svg"
                          alt=""
                        />
                      </span>
                      English
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a
                      href="account/settings.html"
                      className="menu-link d-flex px-5"
                    >
                      <span className="symbol symbol-20px me-4">
                        <img
                          className="rounded-1"
                          src="assets/media/flags/spain.svg"
                          alt=""
                        />
                      </span>
                      Spanish
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a
                      href="account/settings.html"
                      className="menu-link d-flex px-5"
                    >
                      <span className="symbol symbol-20px me-4">
                        <img
                          className="rounded-1"
                          src="assets/media/flags/germany.svg"
                          alt=""
                        />
                      </span>
                      German
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a
                      href="account/settings.html"
                      className="menu-link d-flex px-5"
                    >
                      <span className="symbol symbol-20px me-4">
                        <img
                          className="rounded-1"
                          src="assets/media/flags/japan.svg"
                          alt=""
                        />
                      </span>
                      Japanese
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a
                      href="account/settings.html"
                      className="menu-link d-flex px-5"
                    >
                      <span className="symbol symbol-20px me-4">
                        <img
                          className="rounded-1"
                          src="assets/media/flags/france.svg"
                          alt=""
                        />
                      </span>
                      French
                    </a>
                  </div>
                </div>
              </div>
              <div className="menu-item px-5 my-1">
                <a href="account/settings.html" className="menu-link px-5">
                  Account Settings
                </a>
              </div>
              <div className="menu-item px-5">
                <a
                  href="authentication/layouts/corporate/sign-in.html"
                  className="menu-link px-5"
                >
                  Sign Out
                </a>
              </div>
              {/*end::Menu item*/}
            </div>
            {/*end::User account menu*/}
            {/*end::Menu wrapper*/}
          </div>
          {/*end::User menu*/}
        </div>
      </div>
    </div>
  );
}
