import axionIns from "../axios";

const uploadFile = async (payload) => {
  return axionIns
    .post("uploads/uploadbase4", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

export default {
  uploadFile,
};
