import logo from "./logo.svg";
import "./App.css";
import RouteComponent from "./Router/router";
import Header from "./Components/Header";
import Sidebar from "./Components/Sidebar";
import { useLocation } from "react-router";
import { useEffect, useRef, useState } from "react";
import { RoutesPaths } from "./Router/constant";

function App() {
  // Temporary Removal need to make it from private and public route reducer
  const location = useLocation();
  const [isSidebarHeaderEnabledState, SetisSidebarHeaderEnabledState] = useState(false);

  useEffect(() => {
      SetisSidebarHeaderEnabledState([
        RoutesPaths.LOGIN,
        RoutesPaths.HOME,
      ].includes(location.pathname)? true: false)
  }, [location]);
  // Temporary Removal need to make it from private and public route reducer

  return (
    <>
      {/*begin::App*/}
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        {/*begin::Page*/}
        {isSidebarHeaderEnabledState ? (
          <RouteComponent></RouteComponent>
        ) : (
          <div
            className="app-page flex-column flex-column-fluid"
            id="kt_app_page"
          >
            {/*begin::Header*/}
            <Header />
            {/*end::Header*/}
            {/*begin::Wrapper*/}
            <div
              className="app-wrapper flex-column flex-row-fluid"
              id="kt_app_wrapper"
            >
              {/*begin::Sidebar*/}
              <Sidebar />
              {/*end::Sidebar*/}

              <RouteComponent></RouteComponent>

              {/*end:::Main*/}
            </div>
            {/*end::Wrapper*/}
          </div>
        )}
        {/*end::Page*/}
      </div>
    </>
  );
}

export default App;
