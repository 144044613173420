import React from "react";

export default function RevenueTracking() {
  return (
    <>
      <div className="container-fluid">
        <div className="row p-10">
          <div className="col-md-8">
            <div className="row mb-10">
              <div className="col-md-4">
                <a
                  class="card flex-column justfiy-content-start align-items-start text-start w-100 text-gray-800 text-hover-primary p-10"
                  href=" "
                >
                  <img src="assets/img/chart-simple.svg" alt="image" />
                  <span class="fs-4 fw-bold">500M$ </span>
                  <span class="fs-6 fw-bold text-gray-500">
                    SAP UI Progress{" "}
                  </span>
                </a>
              </div>
              <div className="col-md-4">
                <a
                  class="card flex-column justfiy-content-start align-items-start text-start w-100 text-gray-800 text-hover-primary p-10"
                  href=""
                >
                  <img src="assets/img/chart-simple.svg" alt="image" />
                  <span class="fs-4 fw-bold">400M$ </span>
                  <span class="fs-6 fw-bold text-gray-500">
                    SAP UI Progress{" "}
                  </span>
                </a>
              </div>
              <div className="col-md-4">
                <a
                  class="card flex-column justfiy-content-start align-items-start text-start w-100 text-gray-800 text-hover-primary p-10"
                  href=""
                >
                  <img src="assets/img/chart-simple.svg" alt="image" />
                  <span class="fs-4 fw-bold">300M$ </span>
                  <span class="fs-6 fw-bold text-gray-500">
                    SAP UI Progress{" "}
                  </span>
                </a>
              </div>
            </div>

            <div className="card card-xl-stretch mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">Overview</span>
                  <span className="text-muted mt-1 fw-semibold fs-7">
                    in Milllions
                  </span>
                </h3>
                <div className="card-toolbar">
                  <ul className="nav" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4 me-1 active"
                        data-bs-toggle="tab"
                        href="#kt_table_widget_5_tab_1"
                        aria-selected="true"
                        role="tab"
                      >
                        Month
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4 me-1"
                        data-bs-toggle="tab"
                        href="#kt_table_widget_5_tab_2"
                        aria-selected="false"
                        role="tab"
                        tabIndex={-1}
                      >
                        Week
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4"
                        data-bs-toggle="tab"
                        href="#kt_table_widget_5_tab_3"
                        aria-selected="false"
                        role="tab"
                        tabIndex={-1}
                      >
                        Day
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {/*begin::Body*/}
              <div className="card-body">
                {/*begin::Chart*/}
                <div
                  id="kt_charts_widget_1_chart"
                  style={{ height: 350, minHeight: 365 }}
                >
                  <div
                    id="apexchartszzz80bt"
                    className="apexcharts-canvas apexchartszzz80bt apexcharts-theme-light"
                    style={{ width: 616, height: 350 }}
                  >
                    <svg
                      id="SvgjsSvg1103"
                      width={616}
                      height={350}
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      className="apexcharts-svg"
                      transform="translate(0, 0)"
                      style={{ background: "transparent" }}
                    >
                      <g
                        id="SvgjsG1105"
                        className="apexcharts-inner apexcharts-graphical"
                        transform="translate(47.835205078125, 30)"
                      >
                        <defs id="SvgjsDefs1104">
                          <linearGradient
                            id="SvgjsLinearGradient1109"
                            x1={0}
                            y1={0}
                            x2={0}
                            y2={1}
                          >
                            <stop
                              id="SvgjsStop1110"
                              stopOpacity="0.4"
                              stopColor="rgba(216,227,240,0.4)"
                              offset={0}
                            />
                            <stop
                              id="SvgjsStop1111"
                              stopOpacity="0.5"
                              stopColor="rgba(190,209,230,0.5)"
                              offset={1}
                            />
                            <stop
                              id="SvgjsStop1112"
                              stopOpacity="0.5"
                              stopColor="rgba(190,209,230,0.5)"
                              offset={1}
                            />
                          </linearGradient>
                          <clipPath id="gridRectMaskzzz80bt">
                            <rect
                              id="SvgjsRect1114"
                              width="564.164794921875"
                              height="282.73"
                              x={-3}
                              y={-1}
                              rx={0}
                              ry={0}
                              opacity={1}
                              strokeWidth={0}
                              stroke="none"
                              strokeDasharray={0}
                              fill="#fff"
                            />
                          </clipPath>
                          <clipPath id="forecastMaskzzz80bt" />
                          <clipPath id="nonForecastMaskzzz80bt" />
                          <clipPath id="gridRectMarkerMaskzzz80bt">
                            <rect
                              id="SvgjsRect1115"
                              width="562.164794921875"
                              height="284.73"
                              x={-2}
                              y={-2}
                              rx={0}
                              ry={0}
                              opacity={1}
                              strokeWidth={0}
                              stroke="none"
                              strokeDasharray={0}
                              fill="#fff"
                            />
                          </clipPath>
                        </defs>
                        <rect
                          id="SvgjsRect1113"
                          width="13.954119873046874"
                          height="280.73"
                          x={0}
                          y={0}
                          rx={0}
                          ry={0}
                          opacity={1}
                          strokeWidth={0}
                          strokeDasharray={3}
                          fill="url(#SvgjsLinearGradient1109)"
                          className="apexcharts-xcrosshairs"
                          y2="280.73"
                          filter="none"
                          fillOpacity="0.9"
                        />
                        <g
                          id="SvgjsG1158"
                          className="apexcharts-xaxis"
                          transform="translate(0, 0)"
                        >
                          <g
                            id="SvgjsG1159"
                            className="apexcharts-xaxis-texts-g"
                            transform="translate(0, -4)"
                          >
                            <text
                              id="SvgjsText1161"
                              fontFamily="inherit"
                              x="46.51373291015625"
                              y="309.73"
                              textAnchor="middle"
                              dominantBaseline="auto"
                              fontSize="12px"
                              fontWeight={400}
                              fill="#a1a5b7"
                              className="apexcharts-text apexcharts-xaxis-label "
                              style={{ fontFamily: "inherit" }}
                            >
                              <tspan id="SvgjsTspan1162">Feb</tspan>
                              <title>Feb</title>
                            </text>
                            <text
                              id="SvgjsText1164"
                              fontFamily="inherit"
                              x="139.54119873046875"
                              y="309.73"
                              textAnchor="middle"
                              dominantBaseline="auto"
                              fontSize="12px"
                              fontWeight={400}
                              fill="#a1a5b7"
                              className="apexcharts-text apexcharts-xaxis-label "
                              style={{ fontFamily: "inherit" }}
                            >
                              <tspan id="SvgjsTspan1165">Mar</tspan>
                              <title>Mar</title>
                            </text>
                            <text
                              id="SvgjsText1167"
                              fontFamily="inherit"
                              x="232.56866455078125"
                              y="309.73"
                              textAnchor="middle"
                              dominantBaseline="auto"
                              fontSize="12px"
                              fontWeight={400}
                              fill="#a1a5b7"
                              className="apexcharts-text apexcharts-xaxis-label "
                              style={{ fontFamily: "inherit" }}
                            >
                              <tspan id="SvgjsTspan1168">Apr</tspan>
                              <title>Apr</title>
                            </text>
                            <text
                              id="SvgjsText1170"
                              fontFamily="inherit"
                              x="325.59613037109375"
                              y="309.73"
                              textAnchor="middle"
                              dominantBaseline="auto"
                              fontSize="12px"
                              fontWeight={400}
                              fill="#a1a5b7"
                              className="apexcharts-text apexcharts-xaxis-label "
                              style={{ fontFamily: "inherit" }}
                            >
                              <tspan id="SvgjsTspan1171">May</tspan>
                              <title>May</title>
                            </text>
                            <text
                              id="SvgjsText1173"
                              fontFamily="inherit"
                              x="418.62359619140625"
                              y="309.73"
                              textAnchor="middle"
                              dominantBaseline="auto"
                              fontSize="12px"
                              fontWeight={400}
                              fill="#a1a5b7"
                              className="apexcharts-text apexcharts-xaxis-label "
                              style={{ fontFamily: "inherit" }}
                            >
                              <tspan id="SvgjsTspan1174">Jun</tspan>
                              <title>Jun</title>
                            </text>
                            <text
                              id="SvgjsText1176"
                              fontFamily="inherit"
                              x="511.65106201171875"
                              y="309.73"
                              textAnchor="middle"
                              dominantBaseline="auto"
                              fontSize="12px"
                              fontWeight={400}
                              fill="#a1a5b7"
                              className="apexcharts-text apexcharts-xaxis-label "
                              style={{ fontFamily: "inherit" }}
                            >
                              <tspan id="SvgjsTspan1177">Jul</tspan>
                              <title>Jul</title>
                            </text>
                          </g>
                        </g>
                        <g id="SvgjsG1147" className="apexcharts-grid">
                          <g
                            id="SvgjsG1148"
                            className="apexcharts-gridlines-horizontal"
                          >
                            <line
                              id="SvgjsLine1152"
                              x1={0}
                              y1="70.1825"
                              x2="558.164794921875"
                              y2="70.1825"
                              stroke="#eff2f5"
                              strokeDasharray={4}
                              strokeLinecap="butt"
                              className="apexcharts-gridline"
                            />
                            <line
                              id="SvgjsLine1153"
                              x1={0}
                              y1="140.365"
                              x2="558.164794921875"
                              y2="140.365"
                              stroke="#eff2f5"
                              strokeDasharray={4}
                              strokeLinecap="butt"
                              className="apexcharts-gridline"
                            />
                            <line
                              id="SvgjsLine1154"
                              x1={0}
                              y1="210.5475"
                              x2="558.164794921875"
                              y2="210.5475"
                              stroke="#eff2f5"
                              strokeDasharray={4}
                              strokeLinecap="butt"
                              className="apexcharts-gridline"
                            />
                          </g>
                          <g
                            id="SvgjsG1149"
                            className="apexcharts-gridlines-vertical"
                          />
                          <line
                            id="SvgjsLine1157"
                            x1={0}
                            y1="280.73"
                            x2="558.164794921875"
                            y2="280.73"
                            stroke="transparent"
                            strokeDasharray={0}
                            strokeLinecap="butt"
                          />
                          <line
                            id="SvgjsLine1156"
                            x1={0}
                            y1={1}
                            x2={0}
                            y2="280.73"
                            stroke="transparent"
                            strokeDasharray={0}
                            strokeLinecap="butt"
                          />
                        </g>
                        <g
                          id="SvgjsG1116"
                          className="apexcharts-bar-series apexcharts-plot-series"
                        >
                          <g
                            id="SvgjsG1117"
                            className="apexcharts-series"
                            rel={1}
                            seriesname="NetxProfit"
                          >
                            <path
                              id="SvgjsPath1121"
                              d="M 32.55961303710937 276.731 L 32.55961303710937 181.79666666666668 C 32.55961303710937 179.79666666666668 34.55961303710937 177.79666666666668 36.55961303710937 177.79666666666668 L 40.51373291015625 177.79666666666668 C 42.51373291015625 177.79666666666668 44.51373291015625 179.79666666666668 44.51373291015625 181.79666666666668 L 44.51373291015625 276.731 C 44.51373291015625 278.731 42.51373291015625 280.731 40.51373291015625 280.731 L 36.55961303710937 280.731 C 34.55961303710937 280.731 32.55961303710937 278.731 32.55961303710937 276.731 Z "
                              fill="rgba(0,158,247,1)"
                              fillOpacity={1}
                              stroke="transparent"
                              strokeOpacity={1}
                              strokeLinecap="round"
                              strokeWidth={2}
                              strokeDasharray={0}
                              className="apexcharts-bar-area"
                              index={0}
                              clipPath="url(#gridRectMaskzzz80bt)"
                              pathto="M 32.55961303710937 276.731 L 32.55961303710937 181.79666666666668 C 32.55961303710937 179.79666666666668 34.55961303710937 177.79666666666668 36.55961303710937 177.79666666666668 L 40.51373291015625 177.79666666666668 C 42.51373291015625 177.79666666666668 44.51373291015625 179.79666666666668 44.51373291015625 181.79666666666668 L 44.51373291015625 276.731 C 44.51373291015625 278.731 42.51373291015625 280.731 40.51373291015625 280.731 L 36.55961303710937 280.731 C 34.55961303710937 280.731 32.55961303710937 278.731 32.55961303710937 276.731 Z "
                              pathfrom="M 32.55961303710937 280.731 L 32.55961303710937 280.731 L 44.51373291015625 280.731 L 44.51373291015625 280.731 L 44.51373291015625 280.731 L 44.51373291015625 280.731 L 44.51373291015625 280.731 L 32.55961303710937 280.731 Z"
                              cy="177.79566666666668"
                              cx="124.58707885742187"
                              j={0}
                              val={44}
                              barheight="102.93433333333334"
                              barwidth="13.954119873046874"
                            />
                            <path
                              id="SvgjsPath1123"
                              d="M 125.58707885742187 276.731 L 125.58707885742187 156.06308333333334 C 125.58707885742187 154.06308333333334 127.58707885742189 152.06308333333334 129.5870788574219 152.06308333333334 L 133.54119873046875 152.06308333333334 C 135.54119873046875 152.06308333333334 137.54119873046875 154.06308333333334 137.54119873046875 156.06308333333334 L 137.54119873046875 276.731 C 137.54119873046875 278.731 135.54119873046875 280.731 133.54119873046875 280.731 L 129.58707885742186 280.731 C 127.58707885742186 280.731 125.58707885742187 278.731 125.58707885742187 276.731 Z "
                              fill="rgba(0,158,247,1)"
                              fillOpacity={1}
                              stroke="transparent"
                              strokeOpacity={1}
                              strokeLinecap="round"
                              strokeWidth={2}
                              strokeDasharray={0}
                              className="apexcharts-bar-area"
                              index={0}
                              clipPath="url(#gridRectMaskzzz80bt)"
                              pathto="M 125.58707885742187 276.731 L 125.58707885742187 156.06308333333334 C 125.58707885742187 154.06308333333334 127.58707885742189 152.06308333333334 129.5870788574219 152.06308333333334 L 133.54119873046875 152.06308333333334 C 135.54119873046875 152.06308333333334 137.54119873046875 154.06308333333334 137.54119873046875 156.06308333333334 L 137.54119873046875 276.731 C 137.54119873046875 278.731 135.54119873046875 280.731 133.54119873046875 280.731 L 129.58707885742186 280.731 C 127.58707885742186 280.731 125.58707885742187 278.731 125.58707885742187 276.731 Z "
                              pathfrom="M 125.58707885742187 280.731 L 125.58707885742187 280.731 L 137.54119873046875 280.731 L 137.54119873046875 280.731 L 137.54119873046875 280.731 L 137.54119873046875 280.731 L 137.54119873046875 280.731 L 125.58707885742187 280.731 Z"
                              cy="152.06208333333333"
                              cx="217.6145446777344"
                              j={1}
                              val={55}
                              barheight="128.66791666666668"
                              barwidth="13.954119873046874"
                            />
                            <path
                              id="SvgjsPath1125"
                              d="M 218.6145446777344 276.731 L 218.6145446777344 151.38425 C 218.6145446777344 149.38425 220.6145446777344 147.38425 222.6145446777344 147.38425 L 226.56866455078125 147.38425 C 228.56866455078125 147.38425 230.56866455078125 149.38425 230.56866455078125 151.38425 L 230.56866455078125 276.731 C 230.56866455078125 278.731 228.56866455078125 280.731 226.56866455078125 280.731 L 222.6145446777344 280.731 C 220.6145446777344 280.731 218.6145446777344 278.731 218.6145446777344 276.731 Z "
                              fill="rgba(0,158,247,1)"
                              fillOpacity={1}
                              stroke="transparent"
                              strokeOpacity={1}
                              strokeLinecap="round"
                              strokeWidth={2}
                              strokeDasharray={0}
                              className="apexcharts-bar-area"
                              index={0}
                              clipPath="url(#gridRectMaskzzz80bt)"
                              pathto="M 218.6145446777344 276.731 L 218.6145446777344 151.38425 C 218.6145446777344 149.38425 220.6145446777344 147.38425 222.6145446777344 147.38425 L 226.56866455078125 147.38425 C 228.56866455078125 147.38425 230.56866455078125 149.38425 230.56866455078125 151.38425 L 230.56866455078125 276.731 C 230.56866455078125 278.731 228.56866455078125 280.731 226.56866455078125 280.731 L 222.6145446777344 280.731 C 220.6145446777344 280.731 218.6145446777344 278.731 218.6145446777344 276.731 Z "
                              pathfrom="M 218.6145446777344 280.731 L 218.6145446777344 280.731 L 230.56866455078125 280.731 L 230.56866455078125 280.731 L 230.56866455078125 280.731 L 230.56866455078125 280.731 L 230.56866455078125 280.731 L 218.6145446777344 280.731 Z"
                              cy="147.38325"
                              cx="310.6420104980469"
                              j={2}
                              val={57}
                              barheight="133.34675000000001"
                              barwidth="13.954119873046874"
                            />
                            <path
                              id="SvgjsPath1127"
                              d="M 311.6420104980469 276.731 L 311.6420104980469 153.72366666666667 C 311.6420104980469 151.72366666666667 313.6420104980469 149.72366666666667 315.6420104980469 149.72366666666667 L 319.59613037109375 149.72366666666667 C 321.59613037109375 149.72366666666667 323.59613037109375 151.72366666666667 323.59613037109375 153.72366666666667 L 323.59613037109375 276.731 C 323.59613037109375 278.731 321.59613037109375 280.731 319.59613037109375 280.731 L 315.6420104980469 280.731 C 313.6420104980469 280.731 311.6420104980469 278.731 311.6420104980469 276.731 Z "
                              fill="rgba(0,158,247,1)"
                              fillOpacity={1}
                              stroke="transparent"
                              strokeOpacity={1}
                              strokeLinecap="round"
                              strokeWidth={2}
                              strokeDasharray={0}
                              className="apexcharts-bar-area"
                              index={0}
                              clipPath="url(#gridRectMaskzzz80bt)"
                              pathto="M 311.6420104980469 276.731 L 311.6420104980469 153.72366666666667 C 311.6420104980469 151.72366666666667 313.6420104980469 149.72366666666667 315.6420104980469 149.72366666666667 L 319.59613037109375 149.72366666666667 C 321.59613037109375 149.72366666666667 323.59613037109375 151.72366666666667 323.59613037109375 153.72366666666667 L 323.59613037109375 276.731 C 323.59613037109375 278.731 321.59613037109375 280.731 319.59613037109375 280.731 L 315.6420104980469 280.731 C 313.6420104980469 280.731 311.6420104980469 278.731 311.6420104980469 276.731 Z "
                              pathfrom="M 311.6420104980469 280.731 L 311.6420104980469 280.731 L 323.59613037109375 280.731 L 323.59613037109375 280.731 L 323.59613037109375 280.731 L 323.59613037109375 280.731 L 323.59613037109375 280.731 L 311.6420104980469 280.731 Z"
                              cy="149.72266666666667"
                              cx="403.6694763183594"
                              j={3}
                              val={56}
                              barheight="131.00733333333335"
                              barwidth="13.954119873046874"
                            />
                            <path
                              id="SvgjsPath1129"
                              d="M 404.6694763183594 276.731 L 404.6694763183594 142.02658333333335 C 404.6694763183594 140.02658333333335 406.6694763183594 138.02658333333335 408.6694763183594 138.02658333333335 L 412.62359619140625 138.02658333333335 C 414.62359619140625 138.02658333333335 416.62359619140625 140.02658333333335 416.62359619140625 142.02658333333335 L 416.62359619140625 276.731 C 416.62359619140625 278.731 414.62359619140625 280.731 412.62359619140625 280.731 L 408.6694763183594 280.731 C 406.6694763183594 280.731 404.6694763183594 278.731 404.6694763183594 276.731 Z "
                              fill="rgba(0,158,247,1)"
                              fillOpacity={1}
                              stroke="transparent"
                              strokeOpacity={1}
                              strokeLinecap="round"
                              strokeWidth={2}
                              strokeDasharray={0}
                              className="apexcharts-bar-area"
                              index={0}
                              clipPath="url(#gridRectMaskzzz80bt)"
                              pathto="M 404.6694763183594 276.731 L 404.6694763183594 142.02658333333335 C 404.6694763183594 140.02658333333335 406.6694763183594 138.02658333333335 408.6694763183594 138.02658333333335 L 412.62359619140625 138.02658333333335 C 414.62359619140625 138.02658333333335 416.62359619140625 140.02658333333335 416.62359619140625 142.02658333333335 L 416.62359619140625 276.731 C 416.62359619140625 278.731 414.62359619140625 280.731 412.62359619140625 280.731 L 408.6694763183594 280.731 C 406.6694763183594 280.731 404.6694763183594 278.731 404.6694763183594 276.731 Z "
                              pathfrom="M 404.6694763183594 280.731 L 404.6694763183594 280.731 L 416.62359619140625 280.731 L 416.62359619140625 280.731 L 416.62359619140625 280.731 L 416.62359619140625 280.731 L 416.62359619140625 280.731 L 404.6694763183594 280.731 Z"
                              cy="138.02558333333334"
                              cx="496.6969421386719"
                              j={4}
                              val={61}
                              barheight="142.70441666666667"
                              barwidth="13.954119873046874"
                            />
                            <path
                              id="SvgjsPath1131"
                              d="M 497.6969421386719 276.731 L 497.6969421386719 149.04483333333334 C 497.6969421386719 147.04483333333334 499.6969421386719 145.04483333333334 501.6969421386719 145.04483333333334 L 505.65106201171875 145.04483333333334 C 507.65106201171875 145.04483333333334 509.65106201171875 147.04483333333334 509.65106201171875 149.04483333333334 L 509.65106201171875 276.731 C 509.65106201171875 278.731 507.65106201171875 280.731 505.65106201171875 280.731 L 501.6969421386719 280.731 C 499.6969421386719 280.731 497.6969421386719 278.731 497.6969421386719 276.731 Z "
                              fill="rgba(0,158,247,1)"
                              fillOpacity={1}
                              stroke="transparent"
                              strokeOpacity={1}
                              strokeLinecap="round"
                              strokeWidth={2}
                              strokeDasharray={0}
                              className="apexcharts-bar-area"
                              index={0}
                              clipPath="url(#gridRectMaskzzz80bt)"
                              pathto="M 497.6969421386719 276.731 L 497.6969421386719 149.04483333333334 C 497.6969421386719 147.04483333333334 499.6969421386719 145.04483333333334 501.6969421386719 145.04483333333334 L 505.65106201171875 145.04483333333334 C 507.65106201171875 145.04483333333334 509.65106201171875 147.04483333333334 509.65106201171875 149.04483333333334 L 509.65106201171875 276.731 C 509.65106201171875 278.731 507.65106201171875 280.731 505.65106201171875 280.731 L 501.6969421386719 280.731 C 499.6969421386719 280.731 497.6969421386719 278.731 497.6969421386719 276.731 Z "
                              pathfrom="M 497.6969421386719 280.731 L 497.6969421386719 280.731 L 509.65106201171875 280.731 L 509.65106201171875 280.731 L 509.65106201171875 280.731 L 509.65106201171875 280.731 L 509.65106201171875 280.731 L 497.6969421386719 280.731 Z"
                              cy="145.04383333333334"
                              cx="589.7244079589843"
                              j={5}
                              val={58}
                              barheight="135.68616666666668"
                              barwidth="13.954119873046874"
                            />
                            <g
                              id="SvgjsG1119"
                              className="apexcharts-bar-goals-markers"
                              style={{ pointerEvents: "none" }}
                            >
                              <g
                                id="SvgjsG1120"
                                className="apexcharts-bar-goals-groups"
                              />
                              <g
                                id="SvgjsG1122"
                                className="apexcharts-bar-goals-groups"
                              />
                              <g
                                id="SvgjsG1124"
                                className="apexcharts-bar-goals-groups"
                              />
                              <g
                                id="SvgjsG1126"
                                className="apexcharts-bar-goals-groups"
                              />
                              <g
                                id="SvgjsG1128"
                                className="apexcharts-bar-goals-groups"
                              />
                              <g
                                id="SvgjsG1130"
                                className="apexcharts-bar-goals-groups"
                              />
                            </g>
                          </g>
                          <g
                            id="SvgjsG1132"
                            className="apexcharts-series"
                            rel={2}
                            seriesname="Revenue"
                          >
                            <path
                              id="SvgjsPath1136"
                              d="M 46.51373291015625 276.731 L 46.51373291015625 106.93533333333335 C 46.51373291015625 104.93533333333335 48.51373291015625 102.93533333333335 50.51373291015625 102.93533333333335 L 54.46785278320313 102.93533333333335 C 56.46785278320313 102.93533333333335 58.46785278320313 104.93533333333335 58.46785278320313 106.93533333333335 L 58.46785278320313 276.731 C 58.46785278320313 278.731 56.46785278320313 280.731 54.46785278320313 280.731 L 50.51373291015625 280.731 C 48.51373291015625 280.731 46.51373291015625 278.731 46.51373291015625 276.731 Z "
                              fill="rgba(228,230,239,1)"
                              fillOpacity={1}
                              stroke="transparent"
                              strokeOpacity={1}
                              strokeLinecap="round"
                              strokeWidth={2}
                              strokeDasharray={0}
                              className="apexcharts-bar-area"
                              index={1}
                              clipPath="url(#gridRectMaskzzz80bt)"
                              pathto="M 46.51373291015625 276.731 L 46.51373291015625 106.93533333333335 C 46.51373291015625 104.93533333333335 48.51373291015625 102.93533333333335 50.51373291015625 102.93533333333335 L 54.46785278320313 102.93533333333335 C 56.46785278320313 102.93533333333335 58.46785278320313 104.93533333333335 58.46785278320313 106.93533333333335 L 58.46785278320313 276.731 C 58.46785278320313 278.731 56.46785278320313 280.731 54.46785278320313 280.731 L 50.51373291015625 280.731 C 48.51373291015625 280.731 46.51373291015625 278.731 46.51373291015625 276.731 Z "
                              pathfrom="M 46.51373291015625 280.731 L 46.51373291015625 280.731 L 58.46785278320313 280.731 L 58.46785278320313 280.731 L 58.46785278320313 280.731 L 58.46785278320313 280.731 L 58.46785278320313 280.731 L 46.51373291015625 280.731 Z"
                              cy="102.93433333333334"
                              cx="138.54119873046875"
                              j={0}
                              val={76}
                              barheight="177.79566666666668"
                              barwidth="13.954119873046874"
                            />
                            <path
                              id="SvgjsPath1138"
                              d="M 139.54119873046875 276.731 L 139.54119873046875 85.88058333333333 C 139.54119873046875 83.88058333333333 141.54119873046875 81.88058333333333 143.54119873046875 81.88058333333333 L 147.4953186035156 81.88058333333333 C 149.4953186035156 81.88058333333333 151.4953186035156 83.88058333333333 151.4953186035156 85.88058333333333 L 151.4953186035156 276.731 C 151.4953186035156 278.731 149.4953186035156 280.731 147.4953186035156 280.731 L 143.54119873046875 280.731 C 141.54119873046875 280.731 139.54119873046875 278.731 139.54119873046875 276.731 Z "
                              fill="rgba(228,230,239,1)"
                              fillOpacity={1}
                              stroke="transparent"
                              strokeOpacity={1}
                              strokeLinecap="round"
                              strokeWidth={2}
                              strokeDasharray={0}
                              className="apexcharts-bar-area"
                              index={1}
                              clipPath="url(#gridRectMaskzzz80bt)"
                              pathto="M 139.54119873046875 276.731 L 139.54119873046875 85.88058333333333 C 139.54119873046875 83.88058333333333 141.54119873046875 81.88058333333333 143.54119873046875 81.88058333333333 L 147.4953186035156 81.88058333333333 C 149.4953186035156 81.88058333333333 151.4953186035156 83.88058333333333 151.4953186035156 85.88058333333333 L 151.4953186035156 276.731 C 151.4953186035156 278.731 149.4953186035156 280.731 147.4953186035156 280.731 L 143.54119873046875 280.731 C 141.54119873046875 280.731 139.54119873046875 278.731 139.54119873046875 276.731 Z "
                              pathfrom="M 139.54119873046875 280.731 L 139.54119873046875 280.731 L 151.4953186035156 280.731 L 151.4953186035156 280.731 L 151.4953186035156 280.731 L 151.4953186035156 280.731 L 151.4953186035156 280.731 L 139.54119873046875 280.731 Z"
                              cy="81.87958333333333"
                              cx="231.56866455078125"
                              j={1}
                              val={85}
                              barheight="198.8504166666667"
                              barwidth="13.954119873046874"
                            />
                            <path
                              id="SvgjsPath1140"
                              d="M 232.56866455078125 276.731 L 232.56866455078125 48.44991666666666 C 232.56866455078125 46.44991666666666 234.56866455078125 44.44991666666666 236.56866455078125 44.44991666666666 L 240.5227844238281 44.44991666666666 C 242.5227844238281 44.44991666666666 244.5227844238281 46.44991666666666 244.5227844238281 48.44991666666666 L 244.5227844238281 276.731 C 244.5227844238281 278.731 242.5227844238281 280.731 240.5227844238281 280.731 L 236.56866455078125 280.731 C 234.56866455078125 280.731 232.56866455078125 278.731 232.56866455078125 276.731 Z "
                              fill="rgba(228,230,239,1)"
                              fillOpacity={1}
                              stroke="transparent"
                              strokeOpacity={1}
                              strokeLinecap="round"
                              strokeWidth={2}
                              strokeDasharray={0}
                              className="apexcharts-bar-area"
                              index={1}
                              clipPath="url(#gridRectMaskzzz80bt)"
                              pathto="M 232.56866455078125 276.731 L 232.56866455078125 48.44991666666666 C 232.56866455078125 46.44991666666666 234.56866455078125 44.44991666666666 236.56866455078125 44.44991666666666 L 240.5227844238281 44.44991666666666 C 242.5227844238281 44.44991666666666 244.5227844238281 46.44991666666666 244.5227844238281 48.44991666666666 L 244.5227844238281 276.731 C 244.5227844238281 278.731 242.5227844238281 280.731 240.5227844238281 280.731 L 236.56866455078125 280.731 C 234.56866455078125 280.731 232.56866455078125 278.731 232.56866455078125 276.731 Z "
                              pathfrom="M 232.56866455078125 280.731 L 232.56866455078125 280.731 L 244.5227844238281 280.731 L 244.5227844238281 280.731 L 244.5227844238281 280.731 L 244.5227844238281 280.731 L 244.5227844238281 280.731 L 232.56866455078125 280.731 Z"
                              cy="44.44891666666666"
                              cx="324.59613037109375"
                              j={2}
                              val={101}
                              barheight="236.28108333333336"
                              barwidth="13.954119873046874"
                            />
                            <path
                              id="SvgjsPath1142"
                              d="M 325.59613037109375 276.731 L 325.59613037109375 55.468166666666654 C 325.59613037109375 53.468166666666654 327.59613037109375 51.468166666666654 329.59613037109375 51.468166666666654 L 333.5502502441406 51.468166666666654 C 335.5502502441406 51.468166666666654 337.5502502441406 53.468166666666654 337.5502502441406 55.468166666666654 L 337.5502502441406 276.731 C 337.5502502441406 278.731 335.5502502441406 280.731 333.5502502441406 280.731 L 329.59613037109375 280.731 C 327.59613037109375 280.731 325.59613037109375 278.731 325.59613037109375 276.731 Z "
                              fill="rgba(228,230,239,1)"
                              fillOpacity={1}
                              stroke="transparent"
                              strokeOpacity={1}
                              strokeLinecap="round"
                              strokeWidth={2}
                              strokeDasharray={0}
                              className="apexcharts-bar-area"
                              index={1}
                              clipPath="url(#gridRectMaskzzz80bt)"
                              pathto="M 325.59613037109375 276.731 L 325.59613037109375 55.468166666666654 C 325.59613037109375 53.468166666666654 327.59613037109375 51.468166666666654 329.59613037109375 51.468166666666654 L 333.5502502441406 51.468166666666654 C 335.5502502441406 51.468166666666654 337.5502502441406 53.468166666666654 337.5502502441406 55.468166666666654 L 337.5502502441406 276.731 C 337.5502502441406 278.731 335.5502502441406 280.731 333.5502502441406 280.731 L 329.59613037109375 280.731 C 327.59613037109375 280.731 325.59613037109375 278.731 325.59613037109375 276.731 Z "
                              pathfrom="M 325.59613037109375 280.731 L 325.59613037109375 280.731 L 337.5502502441406 280.731 L 337.5502502441406 280.731 L 337.5502502441406 280.731 L 337.5502502441406 280.731 L 337.5502502441406 280.731 L 325.59613037109375 280.731 Z"
                              cy="51.46716666666666"
                              cx="417.62359619140625"
                              j={3}
                              val={98}
                              barheight="229.26283333333336"
                              barwidth="13.954119873046874"
                            />
                            <path
                              id="SvgjsPath1144"
                              d="M 418.62359619140625 276.731 L 418.62359619140625 81.20175 C 418.62359619140625 79.20175 420.62359619140625 77.20175 422.62359619140625 77.20175 L 426.5777160644531 77.20175 C 428.5777160644531 77.20175 430.5777160644531 79.20175 430.5777160644531 81.20175 L 430.5777160644531 276.731 C 430.5777160644531 278.731 428.5777160644531 280.731 426.5777160644531 280.731 L 422.62359619140625 280.731 C 420.62359619140625 280.731 418.62359619140625 278.731 418.62359619140625 276.731 Z "
                              fill="rgba(228,230,239,1)"
                              fillOpacity={1}
                              stroke="transparent"
                              strokeOpacity={1}
                              strokeLinecap="round"
                              strokeWidth={2}
                              strokeDasharray={0}
                              className="apexcharts-bar-area"
                              index={1}
                              clipPath="url(#gridRectMaskzzz80bt)"
                              pathto="M 418.62359619140625 276.731 L 418.62359619140625 81.20175 C 418.62359619140625 79.20175 420.62359619140625 77.20175 422.62359619140625 77.20175 L 426.5777160644531 77.20175 C 428.5777160644531 77.20175 430.5777160644531 79.20175 430.5777160644531 81.20175 L 430.5777160644531 276.731 C 430.5777160644531 278.731 428.5777160644531 280.731 426.5777160644531 280.731 L 422.62359619140625 280.731 C 420.62359619140625 280.731 418.62359619140625 278.731 418.62359619140625 276.731 Z "
                              pathfrom="M 418.62359619140625 280.731 L 418.62359619140625 280.731 L 430.5777160644531 280.731 L 430.5777160644531 280.731 L 430.5777160644531 280.731 L 430.5777160644531 280.731 L 430.5777160644531 280.731 L 418.62359619140625 280.731 Z"
                              cy="77.20075"
                              cx="510.65106201171875"
                              j={4}
                              val={87}
                              barheight="203.52925000000002"
                              barwidth="13.954119873046874"
                            />
                            <path
                              id="SvgjsPath1146"
                              d="M 511.65106201171875 276.731 L 511.65106201171875 39.09225 C 511.65106201171875 37.09225 513.6510620117188 35.09225 515.6510620117188 35.09225 L 519.6051818847657 35.09225 C 521.6051818847657 35.09225 523.6051818847657 37.09225 523.6051818847657 39.09225 L 523.6051818847657 276.731 C 523.6051818847657 278.731 521.6051818847657 280.731 519.6051818847657 280.731 L 515.6510620117188 280.731 C 513.6510620117188 280.731 511.65106201171875 278.731 511.65106201171875 276.731 Z "
                              fill="rgba(228,230,239,1)"
                              fillOpacity={1}
                              stroke="transparent"
                              strokeOpacity={1}
                              strokeLinecap="round"
                              strokeWidth={2}
                              strokeDasharray={0}
                              className="apexcharts-bar-area"
                              index={1}
                              clipPath="url(#gridRectMaskzzz80bt)"
                              pathto="M 511.65106201171875 276.731 L 511.65106201171875 39.09225 C 511.65106201171875 37.09225 513.6510620117188 35.09225 515.6510620117188 35.09225 L 519.6051818847657 35.09225 C 521.6051818847657 35.09225 523.6051818847657 37.09225 523.6051818847657 39.09225 L 523.6051818847657 276.731 C 523.6051818847657 278.731 521.6051818847657 280.731 519.6051818847657 280.731 L 515.6510620117188 280.731 C 513.6510620117188 280.731 511.65106201171875 278.731 511.65106201171875 276.731 Z "
                              pathfrom="M 511.65106201171875 280.731 L 511.65106201171875 280.731 L 523.6051818847657 280.731 L 523.6051818847657 280.731 L 523.6051818847657 280.731 L 523.6051818847657 280.731 L 523.6051818847657 280.731 L 511.65106201171875 280.731 Z"
                              cy="35.09125"
                              cx="603.6785278320312"
                              j={5}
                              val={105}
                              barheight="245.63875000000002"
                              barwidth="13.954119873046874"
                            />
                            <g
                              id="SvgjsG1134"
                              className="apexcharts-bar-goals-markers"
                              style={{ pointerEvents: "none" }}
                            >
                              <g
                                id="SvgjsG1135"
                                className="apexcharts-bar-goals-groups"
                              />
                              <g
                                id="SvgjsG1137"
                                className="apexcharts-bar-goals-groups"
                              />
                              <g
                                id="SvgjsG1139"
                                className="apexcharts-bar-goals-groups"
                              />
                              <g
                                id="SvgjsG1141"
                                className="apexcharts-bar-goals-groups"
                              />
                              <g
                                id="SvgjsG1143"
                                className="apexcharts-bar-goals-groups"
                              />
                              <g
                                id="SvgjsG1145"
                                className="apexcharts-bar-goals-groups"
                              />
                            </g>
                          </g>
                          <g
                            id="SvgjsG1118"
                            className="apexcharts-datalabels"
                          />
                          <g
                            id="SvgjsG1133"
                            className="apexcharts-datalabels"
                          />
                        </g>
                        <g id="SvgjsG1150" className="apexcharts-grid-borders">
                          <line
                            id="SvgjsLine1151"
                            x1={0}
                            y1={0}
                            x2="558.164794921875"
                            y2={0}
                            stroke="#eff2f5"
                            strokeDasharray={4}
                            strokeLinecap="butt"
                            className="apexcharts-gridline"
                          />
                          <line
                            id="SvgjsLine1155"
                            x1={0}
                            y1="280.73"
                            x2="558.164794921875"
                            y2="280.73"
                            stroke="#eff2f5"
                            strokeDasharray={4}
                            strokeLinecap="butt"
                            className="apexcharts-gridline"
                          />
                        </g>
                        <line
                          id="SvgjsLine1195"
                          x1={0}
                          y1={0}
                          x2="558.164794921875"
                          y2={0}
                          stroke="#b6b6b6"
                          strokeDasharray={0}
                          strokeWidth={1}
                          strokeLinecap="butt"
                          className="apexcharts-ycrosshairs"
                        />
                        <line
                          id="SvgjsLine1196"
                          x1={0}
                          y1={0}
                          x2="558.164794921875"
                          y2={0}
                          strokeDasharray={0}
                          strokeWidth={0}
                          strokeLinecap="butt"
                          className="apexcharts-ycrosshairs-hidden"
                        />
                        <g
                          id="SvgjsG1197"
                          className="apexcharts-yaxis-annotations"
                        />
                        <g
                          id="SvgjsG1198"
                          className="apexcharts-xaxis-annotations"
                        />
                        <g
                          id="SvgjsG1199"
                          className="apexcharts-point-annotations"
                        />
                      </g>
                      <g
                        id="SvgjsG1178"
                        className="apexcharts-yaxis"
                        rel={0}
                        transform="translate(17.835205078125, 0)"
                      >
                        <g id="SvgjsG1179" className="apexcharts-yaxis-texts-g">
                          <text
                            id="SvgjsText1181"
                            fontFamily="inherit"
                            x={20}
                            y="31.4"
                            textAnchor="end"
                            dominantBaseline="auto"
                            fontSize="12px"
                            fontWeight={400}
                            fill="#a1a5b7"
                            className="apexcharts-text apexcharts-yaxis-label "
                            style={{ fontFamily: "inherit" }}
                          >
                            <tspan id="SvgjsTspan1182">120</tspan>
                            <title>120</title>
                          </text>
                          <text
                            id="SvgjsText1184"
                            fontFamily="inherit"
                            x={20}
                            y="101.58250000000001"
                            textAnchor="end"
                            dominantBaseline="auto"
                            fontSize="12px"
                            fontWeight={400}
                            fill="#a1a5b7"
                            className="apexcharts-text apexcharts-yaxis-label "
                            style={{ fontFamily: "inherit" }}
                          >
                            <tspan id="SvgjsTspan1185">90</tspan>
                            <title>90</title>
                          </text>
                          <text
                            id="SvgjsText1187"
                            fontFamily="inherit"
                            x={20}
                            y="171.76500000000001"
                            textAnchor="end"
                            dominantBaseline="auto"
                            fontSize="12px"
                            fontWeight={400}
                            fill="#a1a5b7"
                            className="apexcharts-text apexcharts-yaxis-label "
                            style={{ fontFamily: "inherit" }}
                          >
                            <tspan id="SvgjsTspan1188">60</tspan>
                            <title>60</title>
                          </text>
                          <text
                            id="SvgjsText1190"
                            fontFamily="inherit"
                            x={20}
                            y="241.94750000000002"
                            textAnchor="end"
                            dominantBaseline="auto"
                            fontSize="12px"
                            fontWeight={400}
                            fill="#a1a5b7"
                            className="apexcharts-text apexcharts-yaxis-label "
                            style={{ fontFamily: "inherit" }}
                          >
                            <tspan id="SvgjsTspan1191">30</tspan>
                            <title>30</title>
                          </text>
                          <text
                            id="SvgjsText1193"
                            fontFamily="inherit"
                            x={20}
                            y="312.13"
                            textAnchor="end"
                            dominantBaseline="auto"
                            fontSize="12px"
                            fontWeight={400}
                            fill="#a1a5b7"
                            className="apexcharts-text apexcharts-yaxis-label "
                            style={{ fontFamily: "inherit" }}
                          >
                            <tspan id="SvgjsTspan1194">0</tspan>
                            <title>0</title>
                          </text>
                        </g>
                      </g>
                      <g id="SvgjsG1106" className="apexcharts-annotations" />
                    </svg>
                    <div
                      className="apexcharts-legend"
                      style={{ maxHeight: 175 }}
                    />
                    <div className="apexcharts-tooltip apexcharts-theme-light">
                      <div
                        className="apexcharts-tooltip-title"
                        style={{ fontFamily: "inherit", fontSize: 12 }}
                      />
                      <div
                        className="apexcharts-tooltip-series-group"
                        style={{ order: 1 }}
                      >
                        <span
                          className="apexcharts-tooltip-marker"
                          style={{ backgroundColor: "rgb(0, 158, 247)" }}
                        />
                        <div
                          className="apexcharts-tooltip-text"
                          style={{ fontFamily: "inherit", fontSize: 12 }}
                        >
                          <div className="apexcharts-tooltip-y-group">
                            <span className="apexcharts-tooltip-text-y-label" />
                            <span className="apexcharts-tooltip-text-y-value" />
                          </div>
                          <div className="apexcharts-tooltip-goals-group">
                            <span className="apexcharts-tooltip-text-goals-label" />
                            <span className="apexcharts-tooltip-text-goals-value" />
                          </div>
                          <div className="apexcharts-tooltip-z-group">
                            <span className="apexcharts-tooltip-text-z-label" />
                            <span className="apexcharts-tooltip-text-z-value" />
                          </div>
                        </div>
                      </div>
                      <div
                        className="apexcharts-tooltip-series-group"
                        style={{ order: 2 }}
                      >
                        <span
                          className="apexcharts-tooltip-marker"
                          style={{ backgroundColor: "rgb(228, 230, 239)" }}
                        />
                        <div
                          className="apexcharts-tooltip-text"
                          style={{ fontFamily: "inherit", fontSize: 12 }}
                        >
                          <div className="apexcharts-tooltip-y-group">
                            <span className="apexcharts-tooltip-text-y-label" />
                            <span className="apexcharts-tooltip-text-y-value" />
                          </div>
                          <div className="apexcharts-tooltip-goals-group">
                            <span className="apexcharts-tooltip-text-goals-label" />
                            <span className="apexcharts-tooltip-text-goals-value" />
                          </div>
                          <div className="apexcharts-tooltip-z-group">
                            <span className="apexcharts-tooltip-text-z-label" />
                            <span className="apexcharts-tooltip-text-z-value" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light">
                      <div className="apexcharts-yaxistooltip-text" />
                    </div>
                  </div>
                </div>
                {/*end::Chart*/}
              </div>
              {/*end::Body*/}
            </div>
          </div>
          <div className="col-md-4">
            <div className="card card-flush mb-5">
              <div className="card-header pt-7 mb-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-gray-800">
                    Revenue Breakdown By Category
                  </span>
                </h3>
              </div>
              <div className="card-body pt-0">
                <div className="m-0">
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/TopAuthors.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Top Authors
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            2.6$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/PopularAuthors.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Popular Authors
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            0.4$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/NewUsers.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          New Users
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            0.2$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/ActiveCustomers.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Active Customers
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            4.1$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/BestsellerTheme.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Bestseller Theme
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            8.3$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/FoxBrokerApp.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Fox Broker App
                        </a>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            1.9$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card card-flush mb-5">
              <div className="card-header pt-7 mb-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="fw-bold text-gray-800">
                    Popular services based on Revenue
                  </span>
                </h3>
              </div>
              <div className="card-body pt-0">
                <div className="m-0">
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/TopAuthors.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Top Authors
                        </a>
                        <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                          Mark, Rowling, Esther
                        </span>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            2.6$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/PopularAuthors.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Popular Authors
                        </a>
                        <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                          Randy, Steve, Mike
                        </span>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            0.4$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/NewUsers.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          New Users
                        </a>
                        <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                          John, Pat, Jimmy
                        </span>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            0.2$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/ActiveCustomers.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Active Customers
                        </a>
                        <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                          Mark, Rowling, Esther
                        </span>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            4.1$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-3" />
                  <div className="d-flex flex-stack py-3">
                    <img
                      src="assets/img/BestsellerTheme.svg"
                      className="me-4 w-25px"
                      style={{ borderRadius: 4 }}
                      alt=""
                    />
                    <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                      <div className="me-5">
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Bestseller Theme
                        </a>
                        <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                          Disco, Retro, Sports
                        </span>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="m-0">
                          <span className="badge badge-light-success fs-base">
                            <i className="ki-outline ki-plus fs-5 text-success ms-n1" />
                            8.3$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
