import React from "react";

export default function CreateCoupon() {
  return (
    <>
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="card">
                <div className="card-header">
                  <div className="card-title fs-3 fw-bold">Create Coupon</div>
                </div>
                <form id="kt_project_settings_form" className="form">
                  <div className="card-body p-9">
                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Coupon Name *
                        </div>
                      </div>
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="name"
                          placeholder="Coupon Name"
                        />
                      </div>
                    </div>
                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Coupon Code *
                        </div>
                      </div>
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="type"
                          placeholder="Coupon code"
                        />
                      </div>
                    </div>
                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Discount Type *
                          <i
                            className="fas fa-exclamation-circle ms-1 fs-7"
                            data-bs-toggle="tooltip"
                            aria-label="Country of origination"
                            data-bs-original-title="Country of origination"
                            data-kt-initialized={1}
                          />
                        </div>
                      </div>
                      <div
                        className="col-lg-9 fv-row fv-plugins-icon-container"
                        data-select2-id="select2-data-128-gy6m"
                      >
                        <select
                          className="text-gray-500 fs-6 fw-bold customOptions"
                          data-control="select2"
                          data-hide-search="true"
                          data-placeholder="Select an option"
                          data-kt-table-widget-5="filter_status"
                        >
                          <option />
                          <option value="Show All" selected="selected">
                            Show All
                          </option>
                          <option value="Completed">Completed</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Low Stock">Low Stock</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Discount Amount *
                        </div>
                      </div>
                      <div
                        className="col-lg-9 fv-row fv-plugins-icon-container"
                        data-select2-id="select2-data-128-gy6m"
                      >
                        <select
                          className="text-gray-500 fs-6 fw-bold customOptions"
                          data-control="select2"
                          data-hide-search="true"
                          data-placeholder="Select an option"
                          data-kt-table-widget-5="filter_status"
                        >
                          <option />
                          <option value="Show All" selected="selected">
                            Show All
                          </option>
                          <option value="Completed">Completed</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Low Stock">Low Stock</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mb-8">
                      <label className="col-lg-3 col-form-label fw-semibold fs-6">
                        <span className="required">Minimum Order Amount</span>
                      </label>
                      <div
                        className="col-lg-9 fv-row fv-plugins-icon-container"
                        data-select2-id="select2-data-128-gy6m"
                      >
                        <select
                          className="text-gray-500 fs-6 fw-bold customOptions"
                          data-control="select2"
                          data-hide-search="true"
                          data-placeholder="Select an option"
                          data-kt-table-widget-5="filter_status"
                        >
                          <option />
                          <option value="Show All" selected="selected">
                            Show All
                          </option>
                          <option value="Completed">Completed</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Low Stock">Low Stock</option>
                        </select>
                      </div>
                    </div>

                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Valid From
                        </div>
                      </div>
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="type"
                          placeholder="DD / MM / YYYY"
                        />
                      </div>
                    </div>

                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Valid Until
                        </div>
                      </div>
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="type"
                          placeholder="DD / MM / YYYY"
                        />
                      </div>
                    </div>

                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Usage Limit
                        </div>
                      </div>
                      <div
                        className="col-lg-9 fv-row fv-plugins-icon-container"
                        data-select2-id="select2-data-128-gy6m"
                      >
                        <select
                          className="text-gray-500 fs-6 fw-bold customOptions"
                          data-control="select2"
                          data-hide-search="true"
                          data-placeholder="Select an option"
                          data-kt-table-widget-5="filter_status"
                        >
                          <option />
                          <option value="Show All" selected="selected">
                            Show All
                          </option>
                          <option value="Completed">Completed</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Low Stock">Low Stock</option>
                        </select>
                        <span className="note">
                          Maximum Number of time this coupon can be used
                        </span>
                      </div>
                    </div>

                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Applies To *
                        </div>
                      </div>
                      <div className="col-xl-9">
                        <div className="d-flex fw-semibold h-100">
                          <div className="form-check form-check-custom form-check-solid me-9">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              id="servicsProvider"
                            />
                            <label
                              className="form-check-label ms-3"
                              htmlFor="servicsProvider"
                            >
                              Service Provider Comission
                            </label>
                          </div>
                          <div className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              id="totalProjectFee"
                            />
                            <label
                              className="form-check-label ms-3"
                              htmlFor="totalProjectFee"
                            >
                              User Total Project Fee
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <button
                      type="reset"
                      className="btn btn-light btn-active-light-primary me-2"
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="kt_project_settings_submit"
                    >
                      Create Coupons
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
