import React from "react";
import { NavLink } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";

export default function DiscountCoupon() {
  return (
    <>
      <div className="container-fluid">
        <div className="row discountCouponsSction">
          <div className="col-md-12 discountCouponsBackground">
            <div className="row discountHeader">
              <div className="col-md-6 disountCoupon">
                <h6 className="m-0">Discount Coupons</h6>
              </div>
              <div className="col-md-6 createCouponSection">
                <NavLink to={RoutesPaths.CREATECOUPON}>
                  <button type="submit" className="createCoupon">
                    Create Coupon
                  </button>
                </NavLink>
              </div>
            </div>
            <hr className="hrline m-0"></hr>
            <div className="row couponCardSection">
              <div className="col-md-6 positionSection">
                <div className="couponHeaderBackground">
                  <div className="couponHeader">
                    <div className="">
                      <h6 className="m-0 whiteColor appliesTo">APPLIES TO</h6>
                      <p className="m-0 whiteColor appliesServices">
                        Service Provider Comission
                      </p>
                      <p className="m-0 whiteColor appliesServices">
                        User Project Fee
                      </p>
                    </div>
                    <div className="deleteIcon">
                      <img src="assets/img/Trash.svg" alt="" />
                    </div>
                  </div>

                  <div className="couponMiddle">
                    <h4 className="whiteColor m-0 couponCode">Coupon Code</h4>
                    <h1 className="whiteColor m-0 couponCodeText">ABC123</h1>
                  </div>
                </div>

                <div className="couponBottomBackground">
                  <div className="couponDetails">
                    <p className="m-0 couponDetailsText">VALID FROM</p>
                    <p className="m-0 couponDetailsText">28 Feb,2023</p>
                  </div>
                  <div className="couponDetails">
                    <p className="m-0 couponDetailsText">VALID UNTIL</p>
                    <p className="m-0 couponDetailsText">07 March,2023</p>
                  </div>
                  <div className="couponDetails">
                    <p className="m-0 couponDetailsText">DISCOUNT TYPE</p>
                    <p className="m-0 couponDetailsText">Percentage</p>
                  </div>
                  <div className="couponDetails">
                    <p className="m-0 couponDetailsText">DISCOUNT AMOUNT</p>
                    <p className="m-0 couponDetailsText">10</p>
                  </div>
                  <div className="couponDetails">
                    <p className="m-0 couponDetailsText">
                      MINIMUM ORDER AMOUNT
                    </p>
                    <p className="m-0 couponDetailsText">$250.00</p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 positionSection">
                <div className="couponHeaderBackground rightbackground">
                  <div className="couponHeader">
                    <div className="">
                      <h6 className="m-0 whiteColor appliesTo">APPLIES TO</h6>
                      <p className="m-0 whiteColor appliesServices">
                        Service Provider Comission
                      </p>
                      <p className="m-0 whiteColor appliesServices">
                        User Project Fee
                      </p>
                    </div>
                    <div className="deleteIcon">
                      <img src="assets/img/Trash.svg" alt="" />
                    </div>
                  </div>

                  <div className="couponMiddle">
                    <h4 className="whiteColor m-0 couponCode">Coupon Code</h4>
                    <h1 className="whiteColor m-0 couponCodeText">ABC123</h1>
                  </div>
                </div>

                <div className="couponBottomBackground">
                  <div className="couponDetails">
                    <p className="m-0 couponDetailsText">VALID FROM</p>
                    <p className="m-0 couponDetailsText">28 Feb,2023</p>
                  </div>
                  <div className="couponDetails">
                    <p className="m-0 couponDetailsText">VALID UNTIL</p>
                    <p className="m-0 couponDetailsText">07 March,2023</p>
                  </div>
                  <div className="couponDetails">
                    <p className="m-0 couponDetailsText">DISCOUNT TYPE</p>
                    <p className="m-0 couponDetailsText">Percentage</p>
                  </div>
                  <div className="couponDetails">
                    <p className="m-0 couponDetailsText">DISCOUNT AMOUNT</p>
                    <p className="m-0 couponDetailsText">10</p>
                  </div>
                  <div className="couponDetails">
                    <p className="m-0 couponDetailsText">
                      MINIMUM ORDER AMOUNT
                    </p>
                    <p className="m-0 couponDetailsText">$250.00</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
