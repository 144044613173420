import { GET_BANNER } from "../Action/type";

const initialState = {
  bannerList: [],
  count: 0,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_BANNER:
      return {
        ...state,
        bannerList: payload.banners,
        count: payload?.count,
      };

    default:
      return state;
  }
}
