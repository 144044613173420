import { GET_SERVICE_PROVIDERS } from "../Action/type";

const initialState = {
  serviceProviderList: [],
  count: 0,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SERVICE_PROVIDERS:
      return {
        ...state,
        serviceProviderList: payload?.providers,
        count: payload?.count,
      };

    default:
      return state;
  }
}
