import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";
import { useDispatch, useSelector } from "react-redux";
import { categoryStatusAction, getCategryAction } from "../../Action/Category";
import { backendUrl } from "../../constants";
import useDebounce from "../../Services/debounse";

export default function Category() {
  const { categoryList, count } = useSelector((state) => state.category);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500); // 500ms debounce delay
  const recordsPerPage = 10;
  const totalPages = Math.ceil(count / recordsPerPage);

  const staticId = "newCategory"; // Replace with your static value
  const [showPopup, setShowPopup] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);

  useEffect(() => {
    dispatch(
      getCategryAction(currentPage, recordsPerPage, debouncedSearchQuery)
    );
  }, [dispatch, currentPage, debouncedSearchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 5; // Adjust this to show more/less page numbers

    if (totalPages <= maxPageNumbers) {
      for (let i = 0; i < totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const halfPageNumbers = Math.floor(maxPageNumbers / 2);
      let startPage = Math.max(currentPage - halfPageNumbers, 0);
      let endPage = Math.min(startPage + maxPageNumbers - 1, totalPages - 1);

      if (currentPage > totalPages - halfPageNumbers) {
        startPage = totalPages - maxPageNumbers;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const handleShow = (id, action) => {
    setSelectedId(id);
    setSelectedAction(action);
    setShowPopup(true);
  };

  const handleClose = () => setShowPopup(false);

  const handleYes = (id, action) => {
    var payload = {
      _id: id,
      action: action,
    };
    dispatch(categoryStatusAction(payload))
      .then(() => {
        setShowPopup(false);
        dispatch(getCategryAction(currentPage, recordsPerPage));
      })
      .finally(() => {});
  };

  const handleError = (event) => {
    event.target.src = "assets/img/dummy-image-square.jpg"; // fallback image
  };

  const AlertPopup = ({ show, handleClose, handleYes, id, action }) => {
    return (
      <div
        className={`modal modal-confirmation ${show ? "show" : ""}`}
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-content">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          <h2>Confirmation</h2>
          <p>Are you sure you want to {action} this category?</p>
          <div className="modal-footer">
            <button className="secondary" onClick={handleClose}>
              No
            </button>
            <button className="primary" onClick={() => handleYes(id, action)}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <AlertPopup
        show={showPopup}
        handleClose={handleClose}
        handleYes={handleYes}
        id={selectedId}
        action={selectedAction}
      />
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" className="app-toolbar pt-7 pt-lg-10">
            <div
              id="kt_app_toolbar_container"
              className="app-container container-fluid d-flex align-items-stretch"
            >
              <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                    Category
                  </h1>
                  <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
                    <li className="breadcrumb-item text-muted">
                      Over {count} Categories
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="card">
                <div className="card-header border-0 pt-6 customCategoryHeader">
                  <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                      <i className="ki-outline ki-magnifier fs-3 position-absolute ms-5" />
                      <input
                        type="text"
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-250px ps-13"
                        placeholder="Search Category"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>

                  <NavLink
                    to={{
                      pathname: RoutesPaths.EDITCATEGORY,
                      state: { id: staticId }, // Pass the static ID here
                    }}
                    className="btn btn-flex btn-center btn-sm"
                  >
                    <div className="">
                      <button
                        id="kt_account_deactivate_account_submit"
                        type="submit"
                        className="btn btn-success fw-semibold colorGreen"
                      >
                        Add Category
                      </button>
                    </div>
                  </NavLink>
                </div>
                <div className="card-body py-4">
                  <table
                    className="table align-middle table-row-dashed fs-6 gy-5"
                    id="kt_table_users"
                  >
                    <thead>
                      <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                        <th className="min-w-125px">Category Image</th>
                        <th className="min-w-125px">Title</th>
                        <th className="min-w-200px">Descriptions</th>
                        <th className="text-center min-w-100px">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 fw-semibold">
                      {categoryList?.length > 0 ? (
                        categoryList.map((category, index) => (
                          <tr key={index}>
                            <td>
                              <img
                                src={backendUrl + category.image}
                                alt={category.title}
                                className="categoryImage"
                                onError={handleError} // handle the error event
                              />
                            </td>
                            <td>
                              <div className="d-flex flex-column">
                                <span className="text-gray-800 mb-1">
                                  {category.title}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex flex-column">
                                <span className="text-gray-800 mb-1">
                                  {category.description}
                                </span>
                              </div>
                            </td>
                            <td className="td-last">
                              <NavLink
                                to={RoutesPaths.EDITCATEGORY}
                                state={{ category: category }}
                                className="btn btn-light btn-active-light-primary btn-flex btn-center btn-sm"
                                data-kt-menu-trigger="click"
                                data-kt-menu-placement="bottom-end"
                              >
                                Edit
                              </NavLink>
                              <button
                                id="kt_account_deactivate_account_submit"
                                type="button"
                                className={`btn btn-light fw-semibold ${
                                  category.isActive
                                    ? "btn-light-danger"
                                    : "btn-light-success"
                                }`}
                                onClick={() =>
                                  handleShow(
                                    category._id,
                                    category.isActive ? "inactive" : "active"
                                  )
                                }
                              >
                                {category.isActive ? "Inactive" : "Active"}
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" className="text-center">
                            No categories available.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => handlePageChange(0)}
                      disabled={currentPage === 0}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-double-left"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L6.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0zM7.354 1.646a.5.5 0 0 1 0 .708L2.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z"
                        />
                      </svg>
                    </button>
                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 0}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-left"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L6.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z"
                        />
                      </svg>
                    </button>

                    {generatePageNumbers().map((page, index) => (
                      <button
                        key={index}
                        className={`btn btn-light btn-active-light-primary mx-1 ${
                          page === currentPage ? "active" : ""
                        }`}
                        onClick={() => handlePageChange(page)}
                      >
                        {page + 1}
                      </button>
                    ))}

                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages - 1}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L9.293 7 4.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => handlePageChange(totalPages - 1)}
                      disabled={currentPage === totalPages - 1}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-double-right"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L9.293 7 4.646 2.354a.5.5 0 0 1 0-.708zm4 0a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L13.293 7 8.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
