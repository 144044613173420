import React from "react";
import { NavLink } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";

export default function NotificationCenter() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 sendNotificationSection">
            <div className="card-body sendNotification">
              <div className="mb-5">
                <div className="fs-6 fw-semibold mt-2 mb-3">Title *</div>
                <input
                  type="text"
                  className="form-control form-control-solid bgColorWhite"
                  name="name"
                  placeholder="Notifiaction Title"
                />
              </div>

              <div className="mb-5">
                <div className="fs-6 fw-semibold mt-2 mb-3">Recipient</div>

                <div className="form-check form-check-custom form-check-solid my-3">
                  <input
                    className="form-check-input bgColorWhite"
                    type="checkbox"
                    id="user"
                  />
                  <label className="ms-3 fs-6 fw-semibold" htmlFor="user">
                    User
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid my-3">
                  <input
                    className="form-check-input bgColorWhite"
                    type="checkbox"
                    id="serviceProvider"
                  />
                  <label
                    className="ms-3 fs-6 fw-semibold"
                    htmlFor="serviceProvider"
                  >
                    Service Providers
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid my-3">
                  <input
                    className="form-check-input bgColorWhite"
                    type="checkbox"
                    id="both"
                  />
                  <label className="ms-3 fs-6 fw-semibold" htmlFor="both">
                    Both
                  </label>
                </div>
              </div>

              <div className="mb-5">
                <div className="fs-6 fw-semibold mt-2 mb-3">Message *</div>
                <textarea
                  type="text"
                  className="form-control form-control-solid bgColorWhite"
                  name="name"
                  placeholder="Message"
                />
              </div>

              <div className="mb-5">
                <div className="fs-6 fw-semibold mt-2 mb-3">
                  Notification Type *
                </div>
                <select
                  className="text-gray-500 fs-6 fw-bold customOptions"
                  data-control="select2"
                  data-hide-search="true"
                  data-placeholder="Select an option"
                  data-kt-table-widget-5="filter_status"
                >
                  <option />
                  <option value="Show All" selected="selected">
                    Show All
                  </option>
                  <option value="Completed">Completed</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Low Stock">Low Stock</option>
                </select>
              </div>

              <div className="mb-5">
                <div className="fs-6 fw-semibold mt-2 mb-3">Notify On</div>

                <div className="form-check form-check-custom form-check-solid my-3">
                  <input
                    className="form-check-input bgColorWhite"
                    type="checkbox"
                    id="email"
                  />
                  <label className="ms-3 fs-6 fw-semibold" htmlFor="email">
                    Email
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid my-3">
                  <input
                    className="form-check-input bgColorWhite"
                    type="checkbox"
                    id="inApp"
                  />
                  <label className="ms-3 fs-6 fw-semibold" htmlFor="inApp">
                    In - App
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid my-3">
                  <input
                    className="form-check-input bgColorWhite"
                    type="checkbox"
                    id="sms"
                  />
                  <label className="ms-3 fs-6 fw-semibold" htmlFor="sms">
                    SMS
                  </label>
                </div>
              </div>

              <div className="mb-5">
                <div className="fs-6 fw-semibold mt-2 mb-3">
                  Schedule Notification
                </div>

                <div className="form-check form-check-custom form-check-solid my-3">
                  <input
                    className="form-check-input bgColorWhite"
                    type="checkbox"
                    id="sendImmediate"
                  />
                  <label
                    className="ms-3 fs-6 fw-semibold"
                    htmlFor="sendImmediate"
                  >
                    Send Immediately
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid my-3">
                  <input
                    className="form-check-input bgColorWhite"
                    type="checkbox"
                    id="sendFuture"
                  />
                  <label className="ms-3 fs-6 fw-semibold" htmlFor="sendFuture">
                    Send at a future date and time
                  </label>
                </div>
              </div>

              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  type="reset"
                  className="btn btn-light btn-active-light-primary me-2"
                >
                  save Draft
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  id="kt_project_settings_submit"
                >
                  Send Notification
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div id="kt_app_content" className="app-content flex-column-fluid">
              {/*begin::Content container*/}
              <div id="kt_app_content_container" className="container-fluid">
                {/*begin::Card*/}
                <div className="card">
                  {/*begin::Card body*/}
                  <div className="card-body py-4">
                    {/*begin::Table*/}
                    <table
                      className="table align-middle table-row-dashed fs-6 gy-5"
                      id="kt_table_users"
                    >
                      <thead>
                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                          <th className="min-w-125px">Notification</th>
                          <th className="min-w-125px">Recipients</th>
                          <th className="min-w-100px">Status</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-600 fw-semibold">
                        <tr>
                          <td>
                            <div className="d-flex flex-column">
                              <span className="text-gray-800 mb-1">
                                Notification Title
                              </span>
                              <span>DD/MM/YYYY</span>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <span className="text-gray-800 mb-1">User</span>
                            </div>
                          </td>
                          <td>
                            <NavLink
                              to=""
                              className="me-5 text-danger"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              Save as Draft
                            </NavLink>
                            <NavLink
                              to={RoutesPaths.OVERVIEW_SERVICE_PROVIDER}
                              className="btn btn-light btn-active-light-primary btn-flex btn-center btn-sm me-5"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              View
                            </NavLink>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex flex-column">
                              <span className="text-gray-800 mb-1">
                                Notification Title
                              </span>
                              <span>DD/MM/YYYY</span>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <span className="text-gray-800 mb-1">
                                Service Provider
                              </span>
                            </div>
                          </td>
                          <td>
                            <NavLink
                              to=""
                              className="text-primary me-5"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              Punlished
                            </NavLink>
                            <NavLink
                              to={RoutesPaths.OVERVIEW_SERVICE_PROVIDER}
                              className="btn btn-light btn-active-light-primary btn-flex btn-center btn-sm me-5"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              View
                            </NavLink>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex flex-column">
                              <span className="text-gray-800 mb-1">
                                Notification Title
                              </span>
                              <span>DD/MM/YYYY</span>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <span className="text-gray-800 mb-1">User</span>
                            </div>
                          </td>
                          <td>
                            <NavLink
                              to=""
                              className="text-primary me-5"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              Punlished
                            </NavLink>
                            <NavLink
                              to={RoutesPaths.OVERVIEW_SERVICE_PROVIDER}
                              className="btn btn-light btn-active-light-primary btn-flex btn-center btn-sm me-5"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              View
                            </NavLink>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex flex-column">
                              <span className="text-gray-800 mb-1">
                                Notification Title
                              </span>
                              <span>DD/MM/YYYY</span>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <span className="text-gray-800 mb-1">Both</span>
                            </div>
                          </td>
                          <td>
                            <NavLink
                              to=""
                              className="text-primary me-5"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              Punlished
                            </NavLink>

                            <NavLink
                              to={RoutesPaths.OVERVIEW_SERVICE_PROVIDER}
                              className="btn btn-light btn-active-light-primary btn-flex btn-center btn-sm me-5"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              View
                            </NavLink>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/*end::Table*/}
                  </div>
                  {/*end::Card body*/}
                </div>
                {/*end::Card*/}
              </div>
              {/*end::Content container*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
