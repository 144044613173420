import {
  // SET_USER_PROFILE,
  START_LOADER,
  STOP_LOADER
} from "./type";

// New
// export const updateUserReducerValueAction = (value, reducerName, fieldName) => (dispatch) => {
//   if (reducerName === 'user') {
//     dispatch({ type: SET_USER_PROFILE, payload: { fieldName: fieldName, value: value } });
//   }
// };



export const startLoaderAction = () => (dispatch) => {
  dispatch({ type: START_LOADER });
};

export const stopLoaderAction = () => (dispatch) => {
  dispatch({ type: STOP_LOADER });
};