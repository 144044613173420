import uploadFileService from "../Services/uploadFileService";
import { SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE } from "./type";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const uploadFileAction = (payload) => (dispatch) => {
  dispatch(startLoaderAction());
  return uploadFileService.uploadFile(payload).then((resp) => {
    console.log("resp-------------------------------", resp);
    if (resp.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      dispatch(stopLoaderAction());
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      dispatch(stopLoaderAction());
    }
    return resp;
  });
};
