import { NavLink, useLocation } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";
import { backendUrl } from "../../constants";

export default function ServiceProviderOverview() {
  const location = useLocation();
  const { serviceProvider } = location.state || {};

  console.log("serviceProvider = ", serviceProvider);

  const handleError = (event) => {
    event.target.src = "assets/img/dummy-image-square.jpg"; // fallback image
  };

  return (
    <>
      {/*begin::Main*/}
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        {/*begin::Content wrapper*/}
        <div className="d-flex flex-column flex-column-fluid">
          {/*begin::Toolbar*/}
          {/*begin::Content*/}
          <div id="kt_app_content" className="app-content flex-column-fluid">
            {/*begin::Content container*/}
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <>
                {/*begin::Navbar*/}
                <div className="card mb-5 mb-xxl-8">
                  <div className="card-body pt-9 pb-6">
                    {/*begin::Details*/}
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                      {/*begin: Pic*/}
                      <div className="me-7 mb-4">
                        <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                          <img
                            className="image-holder"
                            src={backendUrl + serviceProvider.profilePic}
                            alt=""
                            onError={handleError} // handle the error event
                          />
                          <div
                            className={`position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px ${
                              serviceProvider.isActive
                                ? "bg-success"
                                : "bg-danger"
                            }`}
                          />
                        </div>
                      </div>
                      {/*end::Pic*/}
                      {/*begin::Info*/}
                      <div className="flex-grow-1">
                        {/*begin::Title*/}
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                          {/*begin::User*/}
                          <div className="d-flex flex-column">
                            {/*begin::Name*/}
                            <div className="d-flex align-items-center mb-2">
                              <span
                                href="#"
                                className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                              >
                                {serviceProvider.fullName}
                              </span>
                              {/* <a href="#"> */}
                              <i className="ki-outline ki-verify fs-1 text-primary" />
                              {/* </a> */}
                            </div>
                            {/*end::Name*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                              <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                <i className="ki-outline ki-profile-circle fs-4 me-1" />
                                Developer
                              </span>
                              <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                <i className="ki-outline ki-geolocation fs-4 me-1" />
                                {serviceProvider.address}
                              </span>
                              <span className="d-flex align-items-center text-gray-500 text-hover-primary mb-2">
                                <i className="ki-outline ki-sms fs-4 me-1" />
                                {serviceProvider.email}
                              </span>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </div>
                        {/*end::Title*/}
                        {/*begin::Stats*/}
                        <div className="d-flex flex-wrap flex-stack">
                          {/*begin::Wrapper*/}
                          <div className="d-flex flex-column flex-grow-1 pe-8">
                            {/*begin::Stats*/}
                            <div className="d-flex flex-wrap">
                              {/*begin::Stat*/}
                              <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                {/*begin::Number*/}
                                <div className="d-flex align-items-center">
                                  <i className="ki-outline ki-arrow-up fs-3 text-success me-2" />
                                  <div
                                    className="fs-2 fw-bold"
                                    data-kt-countup="true"
                                    data-kt-countup-value={4500}
                                    data-kt-countup-prefix="$"
                                  >
                                    0
                                  </div>
                                </div>
                                {/*end::Number*/}
                                {/*begin::Label*/}
                                <div className="fw-semibold fs-6 text-gray-500">
                                  Earnings
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Stat*/}
                              {/*begin::Stat*/}
                              <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                {/*begin::Number*/}
                                <div className="d-flex align-items-center">
                                  <i className="ki-outline ki-arrow-down fs-3 text-danger me-2" />
                                  <div
                                    className="fs-2 fw-bold"
                                    data-kt-countup="true"
                                    data-kt-countup-value={80}
                                  >
                                    0
                                  </div>
                                </div>
                                {/*end::Number*/}
                                {/*begin::Label*/}
                                <div className="fw-semibold fs-6 text-gray-500">
                                  Projects
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Stat*/}
                              {/*begin::Stat*/}
                              <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                {/*begin::Number*/}
                                <div className="d-flex align-items-center">
                                  <i className="ki-outline ki-arrow-up fs-3 text-success me-2" />
                                  <div
                                    className="fs-2 fw-bold"
                                    data-kt-countup="true"
                                    data-kt-countup-value={60}
                                    data-kt-countup-prefix="%"
                                  >
                                    0
                                  </div>
                                </div>
                                {/*end::Number*/}
                                {/*begin::Label*/}
                                <div className="fw-semibold fs-6 text-gray-500">
                                  Success Rate
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Stat*/}
                            </div>
                            {/*end::Stats*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Stats*/}
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::Details*/}
                  </div>
                </div>
                {/*end::Navbar*/}
              </>

              <>
                {/*begin::details View*/}
                <div
                  className="card mb-5 mb-xl-10"
                  id="kt_profile_details_view"
                >
                  {/*begin::Card header*/}
                  <div className="card-header cursor-pointer">
                    {/*begin::Card title*/}
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Profile Details</h3>
                    </div>
                    {/*end::Card title*/}
                    {/*begin::Action*/}
                    <NavLink
                      to={RoutesPaths.MANAGE_SERVICE_PROVIDER}
                      className="btn btn-sm btn-primary align-self-center"
                    >
                      Edit Profile
                    </NavLink>
                    {/*end::Action*/}
                  </div>
                  {/*begin::Card header*/}
                  {/*begin::Card body*/}
                  <div className="card-body p-9">
                    {/*begin::Row*/}
                    <div className="row mb-7">
                      {/*begin::Label*/}
                      <label className="col-lg-4 fw-semibold text-muted">
                        Full Name
                      </label>
                      {/*end::Label*/}
                      {/*begin::Col*/}
                      <div className="col-lg-8">
                        <span className="fw-bold fs-6 text-gray-800">
                          {serviceProvider.fullName}
                        </span>
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Input group*/}
                    <div className="row mb-7">
                      {/*begin::Label*/}
                      <label className="col-lg-4 fw-semibold text-muted">
                        Company
                      </label>
                      {/*end::Label*/}
                      {/*begin::Col*/}
                      <div className="col-lg-8 fv-row">
                        <span className="fw-semibold text-gray-800 fs-6">
                          --
                        </span>
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="row mb-7">
                      {/*begin::Label*/}
                      <label className="col-lg-4 fw-semibold text-muted">
                        Contact Phone
                        <span
                          className="ms-1"
                          data-bs-toggle="tooltip"
                          title="Phone number must be active"
                        >
                          <i className="ki-outline ki-information fs-7" />
                        </span>
                      </label>
                      {/*end::Label*/}
                      {/*begin::Col*/}
                      <div className="col-lg-8 d-flex align-items-center">
                        <span className="fw-bold fs-6 text-gray-800 me-2">
                          {serviceProvider.countryCode}{" "}
                          {serviceProvider.mobileNumber}
                        </span>
                        <span
                          className={`badge ${
                            serviceProvider.isOtpVerified
                              ? "badge-success"
                              : "badge-danger"
                          }`}
                        >
                          {serviceProvider.isOtpVerified
                            ? "Verified"
                            : "Not verified"}
                        </span>
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="row mb-7">
                      {/*begin::Label*/}
                      <label className="col-lg-4 fw-semibold text-muted">
                        Company Site
                      </label>
                      {/*end::Label*/}
                      {/*begin::Col*/}
                      <div className="col-lg-8">
                        <span className="fw-semibold fs-6 text-gray-800 text-hover-primary">
                          --
                        </span>
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="row mb-7">
                      {/*begin::Label*/}
                      <label className="col-lg-4 fw-semibold text-muted">
                        Country
                        <span
                          className="ms-1"
                          data-bs-toggle="tooltip"
                          title="Country of origination"
                        >
                          <i className="ki-outline ki-information fs-7" />
                        </span>
                      </label>
                      {/*end::Label*/}
                      {/*begin::Col*/}
                      <div className="col-lg-8">
                        <span className="fw-bold fs-6 text-gray-800">--</span>
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="row mb-7">
                      {/*begin::Label*/}
                      <label className="col-lg-4 fw-semibold text-muted">
                        Communication
                      </label>
                      {/*end::Label*/}
                      {/*begin::Col*/}
                      <div className="col-lg-8">
                        <span className="fw-bold fs-6 text-gray-800">--</span>
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="row mb-10">
                      {/*begin::Label*/}
                      <label className="col-lg-4 fw-semibold text-muted">
                        Allow Changes
                      </label>
                      {/*begin::Label*/}
                      {/*begin::Label*/}
                      <div className="col-lg-8">
                        <span className="fw-semibold fs-6 text-gray-800">
                          Yes
                        </span>
                      </div>
                      {/*begin::Label*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                  {/*end::Card body*/}
                </div>
                {/*end::details View*/}
              </>

              <>
                <div className="row gy-5 g-xl-12 pb-10">
                  {/*begin::Col*/}
                  <div className="col-xl-12">
                    {/*begin::Table Widget 5*/}
                    <div className="card card-flush h-xl-100">
                      {/*begin::Card header*/}
                      <div className="card-header pt-7">
                        {/*begin::Title*/}
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold text-gray-900">
                            Past Jobs
                          </span>
                          <span className="text-gray-500 mt-1 fw-semibold fs-6">
                            Total no. of Jobs taken from this Platform
                          </span>
                        </h3>
                        {/*end::Title*/}
                        {/*begin::Actions*/}
                        <div className="card-toolbar">
                          {/*begin::Filters*/}
                          <div className="d-flex flex-stack flex-wrap gap-4">
                            {/*begin::Destination*/}
                            <div className="d-flex align-items-center fw-bold">
                              {/*begin::Label*/}
                              <div className="text-muted fs-7 me-2">
                                Cateogry
                              </div>
                              {/*end::Label*/}
                              {/*begin::Select*/}
                              <select
                                className="form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                data-control="select2"
                                data-hide-search="true"
                                data-dropdown-css-class="w-150px"
                                data-placeholder="Select an option"
                              >
                                <option />
                                <option value="Show All" selected="selected">
                                  Show All
                                </option>
                                <option value="a">Category A</option>
                                <option value="b">Category B</option>
                              </select>
                              {/*end::Select*/}
                            </div>
                            {/*end::Destination*/}
                            {/*begin::Status*/}
                            <div className="d-flex align-items-center fw-bold">
                              {/*begin::Label*/}
                              <div className="text-muted fs-7 me-2">Status</div>
                              {/*end::Label*/}
                              {/*begin::Select*/}
                              <select
                                className="form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                data-control="select2"
                                data-hide-search="true"
                                data-dropdown-css-class="w-150px"
                                data-placeholder="Select an option"
                                data-kt-table-widget-5="filter_status"
                              >
                                <option />
                                <option value="Show All" selected="selected">
                                  Show All
                                </option>
                                <option value="Completed">Completed</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Low Stock">Low Stock</option>
                              </select>
                              {/*end::Select*/}
                            </div>
                            {/*end::Status*/}
                            {/*begin::Search*/}
                            <a
                              href="apps/ecommerce/catalog/products.html"
                              className="btn btn-light btn-sm"
                            >
                              View Stock
                            </a>
                            {/*end::Search*/}
                          </div>
                          {/*begin::Filters*/}
                        </div>
                        {/*end::Actions*/}
                      </div>
                      {/*end::Card header*/}
                      {/*begin::Card body*/}
                      <div className="card-body">
                        {/*begin::Table*/}
                        <table
                          className="table align-middle table-row-dashed fs-6 gy-3"
                          id="kt_table_widget_5_table"
                        >
                          {/*begin::Table head*/}
                          <thead>
                            {/*begin::Table row*/}
                            <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                              <th className="min-w-150px">Services</th>
                              <th className="text-end pe-3 min-w-100px">
                                Category
                              </th>
                              <th className="text-end pe-3 min-w-150px">
                                Date Added
                              </th>
                              <th className="text-end pe-3 min-w-100px">
                                Amount
                              </th>
                              <th className="text-end pe-3 min-w-100px">
                                Status
                              </th>
                            </tr>
                            {/*end::Table row*/}
                          </thead>
                          {/*end::Table head*/}
                          {/*begin::Table body*/}
                          <tbody className="fw-bold text-gray-600">
                            <tr>
                              {/*begin::Item*/}
                              <td>
                                <a
                                  href="apps/ecommerce/catalog/edit-product.html"
                                  className="text-gray-900 text-hover-primary"
                                >
                                  Macbook Air M1
                                </a>
                              </td>
                              {/*end::Item*/}
                              {/*begin::Product ID*/}
                              <td className="text-end">#XGY-356</td>
                              {/*end::Product ID*/}
                              {/*begin::Date added*/}
                              <td className="text-end">02 Apr, 2024</td>
                              {/*end::Date added*/}
                              {/*begin::Price*/}
                              <td className="text-end">$1,230</td>
                              {/*end::Price*/}
                              {/*begin::Status*/}
                              <td className="text-end">
                                <span className="badge py-3 px-4 fs-7 badge-light-primary">
                                  Completed
                                </span>
                              </td>
                              {/*end::Status*/}
                            </tr>
                            <tr>
                              {/*begin::Item*/}
                              <td>
                                <a
                                  href="apps/ecommerce/catalog/edit-product.html"
                                  className="text-gray-900 text-hover-primary"
                                >
                                  Surface Laptop 4
                                </a>
                              </td>
                              {/*end::Item*/}
                              {/*begin::Product ID*/}
                              <td className="text-end">#YHD-047</td>
                              {/*end::Product ID*/}
                              {/*begin::Date added*/}
                              <td className="text-end">01 Apr, 2024</td>
                              {/*end::Date added*/}
                              {/*begin::Price*/}
                              <td className="text-end">$1,060</td>
                              {/*end::Price*/}
                              {/*begin::Status*/}
                              <td className="text-end">
                                <span className="badge py-3 px-4 fs-7 badge-light-danger">
                                  In Progress
                                </span>
                              </td>
                              {/*end::Status*/}
                            </tr>
                            <tr>
                              {/*begin::Item*/}
                              <td>
                                <a
                                  href="apps/ecommerce/catalog/edit-product.html"
                                  className="text-gray-900 text-hover-primary"
                                >
                                  Logitech MX 250
                                </a>
                              </td>
                              {/*end::Item*/}
                              {/*begin::Product ID*/}
                              <td className="text-end">#SRR-678</td>
                              {/*end::Product ID*/}
                              {/*begin::Date added*/}
                              <td className="text-end">24 Mar, 2024</td>
                              {/*end::Date added*/}
                              {/*begin::Price*/}
                              <td className="text-end">$64</td>
                              {/*end::Price*/}
                              {/*begin::Status*/}
                              <td className="text-end">
                                <span className="badge py-3 px-4 fs-7 badge-light-primary">
                                  Completed
                                </span>
                              </td>
                              {/*end::Status*/}
                            </tr>
                            <tr>
                              {/*begin::Item*/}
                              <td>
                                <a
                                  href="apps/ecommerce/catalog/edit-product.html"
                                  className="text-gray-900 text-hover-primary"
                                >
                                  AudioEngine HD3
                                </a>
                              </td>
                              {/*end::Item*/}
                              {/*begin::Product ID*/}
                              <td className="text-end">#PXF-578</td>
                              {/*end::Product ID*/}
                              {/*begin::Date added*/}
                              <td className="text-end">24 Mar, 2024</td>
                              {/*end::Date added*/}
                              {/*begin::Price*/}
                              <td className="text-end">$1,060</td>
                              {/*end::Price*/}
                              {/*begin::Status*/}
                              <td className="text-end">
                                <span className="badge py-3 px-4 fs-7 badge-light-danger">
                                  In Progress
                                </span>
                              </td>
                              {/*end::Status*/}
                            </tr>
                            <tr>
                              {/*begin::Item*/}
                              <td>
                                <a
                                  href="apps/ecommerce/catalog/edit-product.html"
                                  className="text-gray-900 text-hover-primary"
                                >
                                  HP Hyper LTR
                                </a>
                              </td>
                              {/*end::Item*/}
                              {/*begin::Product ID*/}
                              <td className="text-end">#PXF-778</td>
                              {/*end::Product ID*/}
                              {/*begin::Date added*/}
                              <td className="text-end">16 Jan, 2024</td>
                              {/*end::Date added*/}
                              {/*begin::Price*/}
                              <td className="text-end">$4500</td>
                              {/*end::Price*/}
                              {/*begin::Status*/}
                              <td className="text-end">
                                <span className="badge py-3 px-4 fs-7 badge-light-primary">
                                  Completed
                                </span>
                              </td>
                              {/*end::Status*/}
                            </tr>
                            <tr>
                              {/*begin::Item*/}
                              <td>
                                <a
                                  href="apps/ecommerce/catalog/edit-product.html"
                                  className="text-gray-900 text-hover-primary"
                                >
                                  Dell 32 UltraSharp
                                </a>
                              </td>
                              {/*end::Item*/}
                              {/*begin::Product ID*/}
                              <td className="text-end">#XGY-356</td>
                              {/*end::Product ID*/}
                              {/*begin::Date added*/}
                              <td className="text-end">22 Dec, 2024</td>
                              {/*end::Date added*/}
                              {/*begin::Price*/}
                              <td className="text-end">$1,060</td>
                              {/*end::Price*/}
                              {/*begin::Status*/}
                              <td className="text-end">
                                <span className="badge py-3 px-4 fs-7 badge-light-warning">
                                  In Progress
                                </span>
                              </td>
                              {/*end::Status*/}
                            </tr>
                            <tr>
                              {/*begin::Item*/}
                              <td>
                                <a
                                  href="apps/ecommerce/catalog/edit-product.html"
                                  className="text-gray-900 text-hover-primary"
                                >
                                  Google Pixel 6 Pro
                                </a>
                              </td>
                              {/*end::Item*/}
                              {/*begin::Product ID*/}
                              <td className="text-end">#XVR-425</td>
                              {/*end::Product ID*/}
                              {/*begin::Date added*/}
                              <td className="text-end">27 Dec, 2024</td>
                              {/*end::Date added*/}
                              {/*begin::Price*/}
                              <td className="text-end">$1,060</td>
                              {/*end::Price*/}
                              {/*begin::Status*/}
                              <td className="text-end">
                                <span className="badge py-3 px-4 fs-7 badge-light-primary">
                                  Completed
                                </span>
                              </td>
                              {/*end::Status*/}
                            </tr>
                          </tbody>
                          {/*end::Table body*/}
                        </table>
                        {/*end::Table*/}
                      </div>
                      {/*end::Card body*/}
                    </div>
                    {/*end::Table Widget 5*/}
                  </div>
                  {/*end::Col*/}
                </div>
              </>

              <>
                {/*begin::Deactivate Account*/}
                <div className="card">
                  {/*begin::Card header*/}
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_deactivate"
                    aria-expanded="true"
                    aria-controls="kt_account_deactivate"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Deactivate Account</h3>
                    </div>
                  </div>
                  {/*end::Card header*/}
                  {/*begin::Content*/}
                  <div
                    id="kt_account_settings_deactivate"
                    className="collapse show"
                  >
                    {/*begin::Form*/}
                    <form id="kt_account_deactivate_form" className="form">
                      {/*begin::Card body*/}
                      <div className="card-body border-top p-9">
                        {/*begin::Notice*/}
                        <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                          {/*begin::Icon*/}
                          <i className="ki-outline ki-information fs-2tx text-warning me-4" />
                          {/*end::Icon*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex flex-stack flex-grow-1">
                            {/*begin::Content*/}
                            <div className="fw-semibold">
                              <h4 className="text-gray-900 fw-bold">
                                You Are Deactivating Your Account
                              </h4>
                              <div className="fs-6 text-gray-700">
                                For extra security, this requires you to confirm
                                your email or phone number when you reset
                                yousignr password.
                                <br />
                                <a className="fw-bold" href="#">
                                  Learn more
                                </a>
                              </div>
                            </div>
                            {/*end::Content*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Notice*/}
                        {/*begin::Form input row*/}
                        <div className="form-check form-check-solid fv-row">
                          <input
                            name="deactivate"
                            className="form-check-input"
                            type="checkbox"
                            defaultValue=""
                            id="deactivate"
                          />
                          <label
                            className="form-check-label fw-semibold ps-2 fs-6"
                            htmlFor="deactivate"
                          >
                            I confirm my account deactivation
                          </label>
                        </div>
                        {/*end::Form input row*/}
                      </div>
                      {/*end::Card body*/}
                      {/*begin::Card footer*/}
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          id="kt_account_deactivate_account_submit"
                          type="submit"
                          className="btn btn-danger fw-semibold"
                        >
                          Deactivate Account
                        </button>
                      </div>
                      {/*end::Card footer*/}
                    </form>
                    {/*end::Form*/}
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Deactivate Account*/}
              </>
            </div>
            {/*end::Content container*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Content wrapper*/}
      </div>
      {/*end:::Main*/}
    </>
  );
}
