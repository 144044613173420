import { GET_SERVICES } from "../Action/type";

const initialState = {
  servicesList: [],
  count: 0,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SERVICES:
      return {
        ...state,
        servicesList: payload?.services,
        count: payload?.count,
      };

    default:
      return state;
  }
}
