export const SET_FAILED_MESSAGE = "SET_FAILED_MESSAGE";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_SERVICES = "GET_SERVICES";
export const GET_BANNER = "GET_BANNER";
export const GET_USER = "GET_USER";
export const GET_SERVICE_PROVIDERS = "GET_SERVICE_PROVIDERS";
