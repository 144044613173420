import userService from "../Services/userService";
import { GET_USER } from "./type";

export const getUserAction = (page, limit, search) => async (dispatch) => {
  return userService.getUser(page, limit, search).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_USER, payload: resp.data });
    } else {
      //   dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
  });
};
