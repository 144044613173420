import { NavLink } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";

export default function Profile() {
  return (
    <>
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              {/* profile view */}
              <div className="card mb-5 mb-xxl-8">
                <div className="card-body pt-9 pb-6">
                  <div className="d-flex flex-wrap flex-sm-nowrap">
                    <div className="me-7 mb-4">
                      <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                        <img src="assets/media/avatars/300-1.jpg" alt="image" />
                        <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-2">
                            <a
                              href="#"
                              className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                            >
                              Max Smith
                            </a>
                            <a href="#">
                              <i className="ki-outline ki-verify fs-1 text-primary" />
                            </a>
                          </div>
                          <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                            <a
                              href="#"
                              className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2"
                            >
                              <i className="ki-outline ki-profile-circle fs-4 me-1" />
                              Developer
                            </a>
                            <a
                              href="#"
                              className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2"
                            >
                              <i className="ki-outline ki-geolocation fs-4 me-1" />
                              SF, Bay Area
                            </a>
                            <a
                              href="#"
                              className="d-flex align-items-center text-gray-500 text-hover-primary mb-2"
                            >
                              <i className="ki-outline ki-sms fs-4 me-1" />
                              max@kt.com
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* profile view end*/}

              {/* Edit Profile */}
              <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                <div className="card-header cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Profile Details</h3>
                  </div>
                  <NavLink
                    to={RoutesPaths.EDITPROFILE}
                    className="btn btn-sm btn-primary align-self-center"
                  >
                    Edit Profile
                  </NavLink>
                </div>
                <div className="card-body p-9">
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">
                      Full Name
                    </label>
                    <div className="col-lg-8">
                      <span className="fw-bold fs-6 text-gray-800">
                        Max Smith
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">
                      Company
                    </label>
                    <div className="col-lg-8 fv-row">
                      <span className="fw-semibold text-gray-800 fs-6">
                        Keenthemes
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">
                      Contact Phone
                      <span
                        className="ms-1"
                        data-bs-toggle="tooltip"
                        title="Phone number must be active"
                      >
                        <i className="ki-outline ki-information fs-7" />
                      </span>
                    </label>
                    <div className="col-lg-8 d-flex align-items-center">
                      <span className="fw-bold fs-6 text-gray-800 me-2">
                        044 3276 454 935
                      </span>
                      <span className="badge badge-success">Verified</span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">
                      Company Site
                    </label>
                    <div className="col-lg-8">
                      <a
                        href="#"
                        className="fw-semibold fs-6 text-gray-800 text-hover-primary"
                      >
                        keenthemes.com
                      </a>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">
                      Country
                      <span
                        className="ms-1"
                        data-bs-toggle="tooltip"
                        title="Country of origination"
                      >
                        <i className="ki-outline ki-information fs-7" />
                      </span>
                    </label>
                    <div className="col-lg-8">
                      <span className="fw-bold fs-6 text-gray-800">
                        Germany
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">
                      Communication
                    </label>
                    <div className="col-lg-8">
                      <span className="fw-bold fs-6 text-gray-800">
                        Email, Phone
                      </span>
                    </div>
                  </div>
                  <div className="row mb-10">
                    <label className="col-lg-4 fw-semibold text-muted">
                      Allow Changes
                    </label>
                    <div className="col-lg-8">
                      <span className="fw-semibold fs-6 text-gray-800">
                        Yes
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* Edit Profile End */}

              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  id="kt_account_deactivate_account_submit"
                  type="submit"
                  className="btn btn-danger fw-semibold"
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
