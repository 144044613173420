import categoryService from "../Services/categoryService";
import { GET_CATEGORY, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE } from "./type";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const addCategoryAction = (payload) => (dispatch) => {
  dispatch(startLoaderAction());
  return categoryService.addCategory(payload).then((resp) => {
    if (resp.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      dispatch(stopLoaderAction());
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      dispatch(stopLoaderAction());
    }
    return Promise.resolve();
  });
};

export const editCategoryAction = (payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  const resp = await categoryService.editCategory(payload);
  if (resp.status) {
    dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    dispatch(stopLoaderAction());
  } else {
    dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    dispatch(stopLoaderAction());
  }
  return await Promise.resolve();
};

export const categoryStatusAction = (payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  const resp = await categoryService.categoryStatusAction(payload);
  if (resp.status) {
    dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    dispatch(stopLoaderAction());
  } else {
    dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    dispatch(stopLoaderAction());
  }
  return await Promise.resolve();
};

export const getCategryAction = (page, limit, search) => async (dispatch) => {
  return categoryService.getCategory(page, limit, search).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_CATEGORY, payload: resp.data });
    } else {
      //   dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
  });
};
