import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";
import { login } from "../../Action/auth";
import { useDispatch, useSelector } from "react-redux";

export default function Login() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const validate = () => {
    let formErrors = {};
    let isValid = true;

    if (!email) {
      isValid = false;
      formErrors["email"] = "Email is required.";
    }

    if (!password) {
      isValid = false;
      formErrors["password"] = "Password is required.";
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validate()) {
      const userData = {
        email: email,
        password: password,
      };
      console.log("User Data:", userData);
      dispatch(login(userData));
      // navigate('/dashboard');
    }
  };

  useEffect(() => {
    getLogin();
  }, [isLoggedIn]);

  const getLogin = () => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row backgroundImg">
          <div className="col-md-12 displaySection">
            <div>
              <img
                src="assets/img/express-job-logo.svg"
                alt="user"
                className="logo"
              />
            </div>

            <div className="row outerLoginForm">
              <div className="col-md-12 titleText">
                <h6 className="m-0">Sign In</h6>
              </div>

              <hr className="hrline"></hr>

              <div className="col-md-12 paddingSection">
                <div className="row inputFieldSection">
                  <div className="col-md-4 inputTitle">
                    <label>Email</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="email"
                      placeholder="Email Id"
                      className="inputField"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                      <div className="error">{errors.email}</div>
                    )}
                  </div>
                </div>
                <div className="row inputFieldSection passwordPadding">
                  <div className="col-md-4 inputTitle">
                    <label>Password</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="password"
                      placeholder="Password"
                      className="inputField"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {errors.password && (
                      <div className="error">{errors.password}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-8">
                    <div className="row reminderPassword">
                      <div className="col-6 col-md-6">
                        <label className="reminderMe">
                          <input type="checkbox" className="checkBoxItem" />
                          Reminder me
                        </label>
                      </div>
                      {/* <div className="col-6 col-md-6 forgotPassword">
                        Forgot Password?
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-12 submitButton">
                        <button type="submit" onClick={handleSubmit}>
                          Login
                        </button>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-md-12">
                        <div className="textOr">OR</div>
                        <div className="socialLogin">
                          <img src="assets/img/google.svg" alt="" />
                          <img src="assets/img/facebook.svg" alt="" />
                          <img src="assets/img/apple.svg" alt="" />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
