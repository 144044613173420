import { LOGIN_FAIL, LOGIN_SUCCESS } from "../Action/type";

  const user = localStorage.getItem("expressjobs") ? JSON.parse(localStorage.getItem("expressjobs")) : null;
  const token = localStorage.getItem("token") ? localStorage.getItem("token") : null;
  const initialState = {
    isLoggedIn: user ? true : false,
    user: user,
    token: token,

  }
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user,
          token: payload.user.token,
        };
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          token: null,
        };

      default:
        return state;
    }
  }
  