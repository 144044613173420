import { GET_USER } from "../Action/type";

const initialState = {
  userList: [],
  count: 0,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER:
      return {
        ...state,
        userList: payload?.users,
        count: payload?.count,
      };

    default:
      return state;
  }
}
