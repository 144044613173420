import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { uploadFileAction } from "../../Action/uploadFile";
import { useDispatch, useSelector } from "react-redux";
import { backendUrl } from "../../constants";
import { addServicesAction, editServicesAction } from "../../Action/Services";
import { RoutesPaths } from "../../Router/constant";
import { useLocation, useNavigate } from "react-router";
import Multiselect from "multiselect-react-dropdown";
import { getCategryAction } from "../../Action/Category";

export default function AddEditServices() {
  const { categoryList } = useSelector((state) => state.category);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
    setError,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {
      status: "active",
      title: "",
      description: "",
      category: [],
    },
    mode: "onChange",
  });

  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const { service } = location.state || {};
  const isActive = service?.isActive;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCategryAction());
    if (isActive !== undefined) {
      setValue("status", isActive ? "active" : "deactive");
    }

    setValue("title", service?.title || "");
    setValue("description", service?.description || "");

    if (service?.image) {
      setImagePreviewUrl(service.image);
    } else {
      setImagePreviewUrl("");
    }

    setSelectedCategory(service?.category || []);
  }, [isActive, service, setValue, dispatch]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(uploadFileAction({ base64: reader.result }))
          .then((response) => {
            setImagePreviewUrl(response?.data?.fileName);
          })
          .catch((error) => {
            console.error(error); // Handle error response
          });
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setImagePreviewUrl("");
  };

  const validateCategory = async () => {
    const selected = selectedCategory.length > 0;
    if (!selected) {
      setError("category", {
        type: "manual",
        message: "At least one category must be selected",
      });
    } else {
      clearErrors("category");
    }
    return selected;
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    if (isSubmitting) return;

    // Trigger validation for Multiselect
    const isCategoryValid = await validateCategory();
    const isFormValid = await trigger();

    if (!isCategoryValid || !isFormValid) return;

    setIsSubmitting(true);
    const isActive = data.status === "active";
    const dataToSave = {
      title: data.title,
      image: imagePreviewUrl || "",
      description: data.description,
      category: selectedCategory,
      parent: "",
      isActive,
      _id: service && service._id ? service._id : "",
    };
    dispatch(
      service ? editServicesAction(dataToSave) : addServicesAction(dataToSave)
    )
      .then(() => {
        navigate(RoutesPaths.SERVICES);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onSelect = (selectedList) => {
    setSelectedCategory(selectedList.map((category) => category._id));
    clearErrors("category");
  };

  const onRemove = (selectedList) => {
    setSelectedCategory(selectedList.map((category) => category._id));
    clearErrors("category");
  };

  return (
    <>
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="card">
                <div className="card-header">
                  <div className="card-title fs-3 fw-bold">
                    {service ? "Edit/" : "Add/"}Service
                  </div>
                </div>
                <form
                  id="kt_project_settings_form"
                  className="form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="card-body p-9">
                    <div className="row mb-5">
                      <div className="col-xl-12">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Service Image
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div
                          className="image-input image-input-outline"
                          data-kt-image-input=" "
                        >
                          <div
                            className="image-input-wrapper w-125px h-125px bgi-position-center"
                            style={{
                              backgroundImage: `url("${
                                imagePreviewUrl
                                  ? `${backendUrl + imagePreviewUrl}`
                                  : "assets/media/svg/avatars/blank.svg"
                              }")`,
                            }}
                          />
                          <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                            data-kt-image-input-action="change"
                            data-bs-toggle="tooltip"
                            title="Change avatar"
                          >
                            <i className="ki-outline ki-pencil fs-7" />
                            <input
                              type="file"
                              name="image"
                              accept=".png, .jpg, .jpeg"
                              onChange={handleImageChange}
                            />
                            <input
                              type="hidden"
                              name="image"
                              {...register("image")}
                            />
                          </label>
                          <span
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                            data-kt-image-input-action="cancel"
                            data-bs-toggle="tooltip"
                            title="Cancel avatar"
                          >
                            <i className="ki-outline ki-cross fs-2" />
                          </span>
                          {imagePreviewUrl && (
                            <span
                              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                              data-kt-image-input-action="remove"
                              data-bs-toggle="tooltip"
                              title="Remove avatar"
                              onClick={removeImage}
                            >
                              <i className="ki-outline ki-cross fs-2" />
                            </span>
                          )}
                        </div>
                        <div className="form-text">
                          Allowed file types: png, jpg, jpeg.
                        </div>
                        {errors.image && (
                          <div className="text-danger">
                            {errors.image.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-8">
                      <div className="col-xl-12">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Services Name
                        </div>
                      </div>
                      <div className="col-xl-12 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="title"
                          placeholder="Enter title"
                          {...register("title", {
                            required: "Title is required",
                          })}
                        />
                        {errors.title && (
                          <div className="text-danger">
                            {errors.title.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-8">
                      <div className="col-ms-12">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Service Category *
                        </div>
                      </div>
                      <div className="col-md-12 fv-row">
                        <div className="col-xl-12 fv-row">
                          <Multiselect
                            name="category"
                            className="form-control form-control-solid"
                            options={categoryList} // Options to display in the dropdown
                            selectedValues={categoryList.filter((item) =>
                              selectedCategory.includes(item._id)
                            )} // Preselected value to persist in dropdown
                            onSelect={onSelect} // Function will trigger on select event
                            onRemove={onRemove} // Function will trigger on remove event
                            displayValue="title" // Property name to display in the dropdown options
                          />
                          {errors.category && (
                            <div className="text-danger">
                              {errors.category.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-8">
                      <div className="col-xl-12">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Services Description
                        </div>
                      </div>
                      <div className="col-xl-12 fv-row">
                        <textarea
                          rows="2"
                          className="form-control form-control-solid"
                          name="description"
                          placeholder="Description"
                          {...register("description", {
                            required: "Description is required",
                          })}
                        />
                        {errors.description && (
                          <div className="text-danger">
                            {errors.description.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-8">
                      <div className="col-md-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Active Service
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="d-flex fw-semibold h-100 radio-group">
                          <div className="form-check form-check-custom form-check-solid me-9">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="active"
                              id="active"
                              {...register("status")}
                              name="status"
                            />
                            <label
                              className="form-check-label ps-3 ms-0"
                              htmlFor="active"
                            >
                              Active
                            </label>
                          </div>
                          <div className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="deactive"
                              id="deactive"
                              {...register("status")}
                              name="status"
                            />
                            <label
                              className="form-check-label ps-3 ms-0"
                              htmlFor="deactive"
                            >
                              De-active
                            </label>
                          </div>
                        </div>
                      </div>
                      {/*end::Col*/}
                    </div>
                  </div>

                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <button
                      type="reset"
                      className="btn btn-light btn-active-light-primary me-2"
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="kt_project_settings_submit"
                      disabled={!isValid} // Disable button if form is not valid
                    >
                      {service ? "Save Changes" : "Save"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// import React, { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
// import { uploadFileAction } from "../../Action/uploadFile";
// import { useDispatch, useSelector } from "react-redux";
// import { backendUrl } from "../../constants";
// import { addServicesAction, editServicesAction } from "../../Action/Services";
// import { RoutesPaths } from "../../Router/constant";
// import { useLocation, useNavigate } from "react-router";
// import Multiselect from "multiselect-react-dropdown";
// import { getCategryAction } from "../../Action/Category";

// export default function AddEditServices() {
//   const { categoryList } = useSelector((state) => state.category);
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     setValue,
//   } = useForm({
//     defaultValues: {
//       status: "active", // Set default value here
//     },
//   });
//   const [imagePreviewUrl, setImagePreviewUrl] = useState();
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const { service } = location.state || {};
//   const isActive = service?.isActive;

//   useEffect(() => {
//     dispatch(getCategryAction());
//     if (isActive !== undefined) {
//       setValue("status", isActive ? "active" : "deactive");
//     }

//     setValue("title", service?.title || "");
//     setValue("description", service?.description || "");

//     if (service?.image) {
//       setImagePreviewUrl(service.image);
//     } else {
//       setImagePreviewUrl("");
//     }
//   }, [isActive, service, setValue, dispatch]);

//   const navigate = useNavigate();

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         dispatch(uploadFileAction({ base64: reader.result }))
//           .then((response) => {
//             setImagePreviewUrl(response?.data?.fileName);
//           })
//           .catch((error) => {
//             console.error(error); // Handle error response
//           });
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const removeImage = () => {
//     setImagePreviewUrl("");
//   };

//   const onSubmit = (data, event) => {
//     event.preventDefault();
//     if (isSubmitting) return;

//     setIsSubmitting(true);
//     const isActive = data.status === "active";
//     const dataToSave = {
//       title: data.title,
//       image: imagePreviewUrl || "",
//       description: data.description,
//       category: selectedCategory,
//       parent: "",
//       isActive,
//       _id: service && service._id ? service._id : "",
//     };

//     dispatch(
//       service ? editServicesAction(dataToSave) : addServicesAction(dataToSave)
//     )
//       .then(() => {
//         navigate(RoutesPaths.SERVICES);
//       })
//       .finally(() => {
//         setIsSubmitting(false);
//       });
//   };

//   const state = {
//     options: categoryList,
//     selectedValue: categoryList.filter(item => service?.category.includes(item._id))
//   };

//   var selectedCategory = []

//   const onSelect = (selectedList, selectedItem) => {
//     selectedCategory = selectedList.map(category => category._id)
//   };
//   const onRemove = (selectedList, removedItem) => {
//     selectedCategory = selectedList.map(category => category._id)
//   };

//   return (
//     <>
//       <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
//         <div className="d-flex flex-column flex-column-fluid">
//           <div id="kt_app_content" className="app-content flex-column-fluid">
//             <div
//               id="kt_app_content_container"
//               className="app-container container-fluid"
//             >
//               <div className="card">
//                 <div className="card-header">
//                   <div className="card-title fs-3 fw-bold">
//                     {service ? "Edit/" : "Add/"}Service
//                   </div>
//                 </div>
//                 <form
//                   id="kt_project_settings_form"
//                   className="form"
//                   onSubmit={handleSubmit(onSubmit)}
//                 >
//                   <div className="card-body p-9">
//                     <div className="row mb-5">
//                       <div className="col-xl-12">
//                         <div className="fs-6 fw-semibold mt-2 mb-3">
//                           Service Image
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div
//                           className="image-input image-input-outline"
//                           data-kt-image-input=" "
//                         >
//                           <div
//                             className="image-input-wrapper w-125px h-125px bgi-position-center"
//                             style={{
//                               backgroundImage: `url("${
//                                 imagePreviewUrl
//                                   ? `${backendUrl + imagePreviewUrl}`
//                                   : "assets/media/svg/avatars/blank.svg"
//                               }")`,
//                             }}
//                           />
//                           <label
//                             className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
//                             data-kt-image-input-action="change"
//                             data-bs-toggle="tooltip"
//                             title="Change avatar"
//                           >
//                             <i className="ki-outline ki-pencil fs-7" />
//                             <input
//                               type="file"
//                               name="image"
//                               accept=".png, .jpg, .jpeg"
//                               onChange={handleImageChange}
//                             />
//                             <input
//                               type="hidden"
//                               name="image"
//                               {...register("image")}
//                             />
//                           </label>
//                           <span
//                             className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
//                             data-kt-image-input-action="cancel"
//                             data-bs-toggle="tooltip"
//                             title="Cancel avatar"
//                           >
//                             <i className="ki-outline ki-cross fs-2" />
//                           </span>
//                           {imagePreviewUrl && (
//                             <span
//                               className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
//                               data-kt-image-input-action="remove"
//                               data-bs-toggle="tooltip"
//                               title="Remove avatar"
//                               onClick={removeImage}
//                             >
//                               <i className="ki-outline ki-cross fs-2" />
//                             </span>
//                           )}
//                         </div>
//                         <div className="form-text">
//                           Allowed file types: png, jpg, jpeg.
//                         </div>
//                         {errors.image && (
//                           <div className="text-danger">
//                             {errors.image.message}
//                           </div>
//                         )}
//                       </div>
//                     </div>

//                     <div className="row mb-8">
//                       <div className="col-xl-12">
//                         <div className="fs-6 fw-semibold mt-2 mb-3">
//                           Services Name
//                         </div>
//                       </div>
//                       <div className="col-xl-12 fv-row">
//                         <input
//                           type="text"
//                           className="form-control form-control-solid"
//                           name="title"
//                           placeholder="Enter title"
//                           {...register("title")}
//                         />
//                         {errors.title && (
//                           <div className="text-danger">
//                             {errors.title.message}
//                           </div>
//                         )}
//                       </div>
//                     </div>

//                     <div className="row mb-8">
//                       <div className="col-ms-12">
//                         <div className="fs-6 fw-semibold mt-2 mb-3">
//                           Service Category *
//                         </div>
//                       </div>
//                       <div className="col-md-12 fv-row">

//                         <div className="col-xl-12 fv-row">
//                           <Multiselect
//                             name="category"
//                             className="form-control form-control-solid"
//                             options={state.options} // Options to display in the dropdown
//                             selectedValues={state.selectedValue} // Preselected value to persist in dropdown
//                             onSelect={onSelect} // Function will trigger on select event
//                             onRemove={onRemove} // Function will trigger on remove event
//                             displayValue="title" // Property name to display in the dropdown options
//                             // {...register("category")}
//                           />
//                           {errors.title && (
//                             <div className="text-danger">
//                               {errors.category.message}
//                             </div>
//                           )}
//                         </div>
//                       </div>
//                     </div>

//                     <div className="row mb-8">
//                       <div className="col-xl-12">
//                         <div className="fs-6 fw-semibold mt-2 mb-3">
//                           Services Description
//                         </div>
//                       </div>
//                       <div className="col-xl-12 fv-row">
//                         <textarea
//                           rows="2"
//                           type="text"
//                           className="form-control form-control-solid"
//                           name="description"
//                           placeholder="Description"
//                           {...register("description")}
//                         />
//                         {errors.description && (
//                           <div className="text-danger">
//                             {errors.description.message}
//                           </div>
//                         )}
//                       </div>
//                     </div>

//                     <div className="row mb-8">
//                       <div className="col-md-3">
//                         <div className="fs-6 fw-semibold mt-2 mb-3">
//                           Active Service
//                         </div>
//                       </div>
//                       <div className="col-md-9">
//                         <div className="d-flex fw-semibold h-100 radio-group">
//                           <div className="form-check form-check-custom form-check-solid me-9">
//                             <input
//                               className="form-check-input"
//                               type="radio"
//                               value="active"
//                               id="active"
//                               {...register("status")}
//                               name="status"
//                             />
//                             <label
//                               className="form-check-label ps-3 ms-0"
//                               htmlFor="active"
//                             >
//                               Active
//                             </label>
//                           </div>
//                           <div className="form-check form-check-custom form-check-solid">
//                             <input
//                               className="form-check-input"
//                               type="radio"
//                               value="deactive"
//                               id="deactive"
//                               {...register("status")}
//                               name="status"
//                             />
//                             <label
//                               className="form-check-label ps-3 ms-0"
//                               htmlFor="deactive"
//                             >
//                               De-active
//                             </label>
//                           </div>
//                         </div>
//                       </div>
//                       {/*end::Col*/}
//                     </div>
//                   </div>

//                   <div className="card-footer d-flex justify-content-end py-6 px-9">
//                     <button
//                       type="reset"
//                       className="btn btn-light btn-active-light-primary me-2"
//                     >
//                       Discard
//                     </button>
//                     <button
//                       type="submit"
//                       className="btn btn-primary"
//                       id="kt_project_settings_submit"
//                     >
//                       {service ? "Save Changes" : "Save"}
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
