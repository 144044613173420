import axionIns from "../axios";

const addBanner = async (payload) => {
  return axionIns
    .post("admin/banner", JSON.stringify(payload))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

const getBanner = async (page, limit) => {
  return axionIns
    .get(`admin/banner?offset=${page}&limit=${limit ? limit : 25}`)
    .then((response) => {
      return response.data;
    });
};

const editBanner = async (payload) => {
  return axionIns
    .put("admin/banner/" + payload._id, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

export default {
  addBanner,
  getBanner,
  editBanner,
};
