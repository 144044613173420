import { combineReducers } from "redux";
import auth from "./auth";
import category from "./category";
import services from "./services";
import banner from "./banner";
import user from "./user";
import serviceProvider from "./serviceProvider";

const rootReducer = combineReducers({
  auth,
  category,
  services,
  banner,
  user,
  serviceProvider,
});


export default rootReducer;
