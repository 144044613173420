import bannerServices from "../Services/bannerServices";
import { GET_BANNER, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE } from "./type";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const addBannerAction = (payload) => (dispatch) => {
  dispatch(startLoaderAction());
  return bannerServices.addBanner(payload).then((resp) => {
    if (resp.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      dispatch(stopLoaderAction());
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      dispatch(stopLoaderAction());
    }
    return Promise.resolve();
  });
};

export const getBannerAction = (page, limit) => async (dispatch) => {
  return bannerServices.getBanner(page, limit).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_BANNER, payload: resp.data });
    } else {
      //   dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
  });
};

export const editBannerAction = (payload) => async (dispatch) => {
  dispatch(startLoaderAction());
  const resp = await bannerServices.editBanner(payload);
  if (resp.status) {
    dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
    dispatch(stopLoaderAction());
  } else {
    dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    dispatch(stopLoaderAction());
  }
  return await Promise.resolve();
};
