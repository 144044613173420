import { useEffect, useState } from "react";
import { backendUrl } from "../../constants";
import { uploadFileAction } from "../../Action/uploadFile";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { addBannerAction, editBannerAction } from "../../Action/banner";
import { RoutesPaths } from "../../Router/constant";
import { useLocation, useNavigate } from "react-router";

export default function Banner() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
  } = useForm({
    mode: "onChange", // Enable validation on change
  });

  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const [role, setRole] = useState("");
  const [type, setType] = useState("");

  const location = useLocation();
  const { banner } = location.state || {};

  useEffect(() => {
    if (banner?.image) {
      setImagePreviewUrl(banner.image);
    } else {
      setImagePreviewUrl("");
    }

    setValue("role", banner?.role || "");
    setRole(banner?.role || "");
    setValue("type", banner?.type || "");
    setType(banner?.type || "");
    setValue("typeId", banner?.typeId || "");

    setStatus(banner?.isActive ? true : false);
  }, [setValue, setType, setRole]);

  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(uploadFileAction({ base64: reader.result }))
          .then((response) => {
            setImagePreviewUrl(response?.data?.fileName);
          })
          .catch((error) => {
            console.error(error); // Handle error response
          });
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setImagePreviewUrl("");
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    const dataToSave = {
      image: imagePreviewUrl || "",
      role: data.role,
      type: data.type,
      typeId: data.type != "5" ? data.typeId : null,
      isActive: status,
      _id: banner && banner._id ? banner._id : "",
    };
    dispatch(
      banner ? editBannerAction(dataToSave) : addBannerAction(dataToSave)
    )
      .then(() => {
        navigate(RoutesPaths.BANNERS);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="card">
                <div className="card-header">
                  <div className="card-title fs-3 fw-bold">Add/Edit Banner</div>
                </div>

                <form
                  id="kt_project_settings_form"
                  className="form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="card-body p-9">
                    <div className="row mb-5">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Banner Image
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div
                          className="image-input image-input-outline"
                          data-kt-image-input="true"
                        >
                          <div
                            className="image-input-wrapper w-125px h-125px bgi-position-center"
                            style={{
                              backgroundImage: `url("${
                                imagePreviewUrl
                                  ? `${backendUrl + imagePreviewUrl}`
                                  : "assets/img/dummy-image-square.jpg"
                              }")`,
                            }}
                          />
                          <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                            data-kt-image-input-action="change"
                            data-bs-toggle="tooltip"
                            title="Change avatar"
                          >
                            <i className="ki-outline ki-pencil fs-7" />
                            <input
                              type="file"
                              name="image"
                              accept=".png, .jpg, .jpeg"
                              onChange={handleImageChange}
                            />
                            <input
                              type="hidden"
                              name="image"
                              {...register("image")}
                            />
                          </label>
                          <span
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                            data-kt-image-input-action="cancel"
                            data-bs-toggle="tooltip"
                            title="Cancel avatar"
                          >
                            <i className="ki-outline ki-cross fs-2" />
                          </span>
                          {imagePreviewUrl && (
                            <span
                              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                              data-kt-image-input-action="remove"
                              data-bs-toggle="tooltip"
                              title="Remove avatar"
                              onClick={removeImage}
                            >
                              <i className="ki-outline ki-cross fs-2" />
                            </span>
                          )}
                        </div>
                        <div className="form-text">
                          Allowed file types: png, jpg, jpeg.
                        </div>
                        {errors.image && (
                          <div className="text-danger">
                            {errors.image.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">Role</div>
                      </div>
                      <div className="col-xl-9 fv-row">
                        <select
                          className="form-control-solid fs-6 fw-bold customOptions"
                          name="role"
                          {...register("role", {
                            required: "Banner role is required.",
                            onChange: (e) => setRole(e.target.value),
                          })}
                        >
                          <option value="" disabled selected hidden>
                            Select an option
                          </option>
                          <option value="1">Customer</option>
                          <option value="2">Provider</option>
                        </select>
                        {errors.role && (
                          <div className="text-danger">
                            {errors.role.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">Type</div>
                      </div>
                      <div className="col-xl-9 fv-row">
                        <select
                          className="form-control-solid fs-6 fw-bold customOptions"
                          name="type"
                          {...register("type", {
                            required: "Banner type is required.",
                            onChange: (e) => setType(e.target.value),
                          })}
                          disabled={!role}
                        >
                          <option value="" disabled selected hidden>
                            Select an option
                          </option>
                          {role == "1" && (
                            <>
                              <option value="2">Provider</option>
                              <option value="3">Service</option>
                            </>
                          )}
                          <option value="4">External link</option>
                          <option value="5">No CTA {type}</option>
                        </select>
                        {errors.type && (
                          <div className="text-danger">
                            {errors.type.message}
                          </div>
                        )}
                      </div>
                    </div>

                    {type != "5" && (
                      <>
                        <div className="row mb-8">
                          <div className="col-xl-3">
                            <div className="fs-6 fw-semibold mt-2 mb-3">
                              {type === "4" ? "External link" : "Type Id"}
                            </div>
                          </div>
                          <div className="col-xl-9 fv-row">
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              name="typeId"
                              placeholder={
                                type === "4" ? "External link" : "Type Id"
                              }
                              {...register("typeId", {
                                required: "Type id is required.",
                              })}
                            />
                            {errors.typeId && (
                              <div className="text-danger">
                                {errors.typeId.message}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="row mb-8">
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">Status</div>
                      </div>
                      <div className="col-xl-9 fv-row">
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="status"
                            name="status"
                            checked={status}
                            onChange={() => setStatus(!status)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <button
                      type="reset"
                      className="btn btn-light btn-active-light-primary me-2"
                      onClick={() => reset()}
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting || !isValid}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
