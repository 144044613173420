import { createStore, applyMiddleware } from 'redux';
import { thunk } from "redux-thunk";
import rootReducer from "./Reducer";
const middleware = [thunk];

const store = createStore(
  rootReducer,
  applyMiddleware(...middleware),
  // composeEnhancers()
);

export default store;
