import authServices from "../Services/authServices";
import { secretPass } from "../constants";
import { LOGIN_FAIL, LOGIN_SUCCESS, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE } from "./type";
import CryptoJS from "crypto-js";
import { startLoaderAction } from "./updateReducerValue";

export const login = (payload) => (dispatch) => {
  dispatch(startLoaderAction());
  return authServices.login(payload).then((resp) => {
    if (resp.status) {
      dispatch(setLogin(resp.data));
      setLocalStorageRememberMe(payload);
    } else {
      dispatch({ type: LOGIN_FAIL });
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
    return Promise.resolve();
  });
};


const setLocalStorageRememberMe = (payload) => {
    if (payload.rememberme) {
      const data = CryptoJS.AES.encrypt(
        JSON.stringify(payload.password),
        secretPass
      ).toString();
      payload.password = data;
      localStorage.setItem("credential", JSON.stringify({ payload }));
    } else
      localStorage.removeItem("credential");
  }
  
  const setLogin = (data) => (dispatch) => {
    if (data && data.token) {
      setLocalStorage(data)
      dispatch({ type: LOGIN_SUCCESS, payload: { user: data }, });
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: "Login successfully", });
    }
  }

  const setLocalStorage = (data) => {
    if (data && data.token) {
      localStorage.setItem("expressjobs", JSON.stringify(data));
      localStorage.setItem("token", data.token);
    }
  }