import servicesServices from "../Services/servicesServices";
import { GET_SERVICES, SET_FAILED_MESSAGE, SET_SUCCESS_MESSAGE } from "./type";
import { startLoaderAction, stopLoaderAction } from "./updateReducerValue";

export const addServicesAction = (payload) => (dispatch) => {
  dispatch(startLoaderAction());
  return servicesServices.addServices(payload).then((resp) => {
    if (resp.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      dispatch(stopLoaderAction());
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      dispatch(stopLoaderAction());
    }
    return Promise.resolve();
  });
};

export const editServicesAction = (payload) => (dispatch) => {
  dispatch(startLoaderAction());
  return servicesServices.editService(payload).then((resp) => {
    if (resp.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      dispatch(stopLoaderAction());
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      dispatch(stopLoaderAction());
    }
    return Promise.resolve();
  });
};

export const deleteServicesAction = (payload) => (dispatch) => {
  dispatch(startLoaderAction());
  return servicesServices.deleteService(payload).then((resp) => {
    if (resp.status) {
      dispatch({ type: SET_SUCCESS_MESSAGE, payload: resp.data });
      dispatch(stopLoaderAction());
    } else {
      dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
      dispatch(stopLoaderAction());
    }
    return Promise.resolve();
  });
};

export const getServicesAction = (page, limit, search) => async (dispatch) => {
  return servicesServices.getServices(page, limit, search).then((resp) => {
    if (resp.status) {
      dispatch({ type: GET_SERVICES, payload: resp.data });
    } else {
      //   dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
    }
  });
};
