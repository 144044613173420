import serviceProvider from "../Services/serviceProvider";
import { GET_SERVICE_PROVIDERS } from "./type";

export const getServiceProvidersAction =
  (page, limit, search) => async (dispatch) => {
    return serviceProvider
      .getServiceProviders(page, limit, search)
      .then((resp) => {
        if (resp.status) {
          dispatch({ type: GET_SERVICE_PROVIDERS, payload: resp.data });
        } else {
          //   dispatch({ type: SET_FAILED_MESSAGE, payload: resp.data });
        }
      });
  };
