import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { backendUrl } from "../../constants";
import { useLocation, useNavigate } from "react-router";
import { uploadFileAction } from "../../Action/uploadFile";
// import { RoutesPaths } from "../../Router/constant";

export default function ServiceProviderManage() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    reset,
  } = useForm({
    mode: "onChange", // Enable validation on change
  });

  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const [status, setStatus] = useState(false);
  const [comEmail, setComEmail] = useState(false);
  const [comPhone, setComPhone] = useState(false);

  useEffect(() => {
    setValue("name", "");
    setValue("company", "");
    setValue("phone", "");
    setValue("site", "");
    setValue("country", "");
    setValue("language", "");
    setValue("timeZone", "");
    setValue("currency", "");
    setComEmail(true);
    setComPhone(false);
    setStatus(false);
  }, [setValue]);

  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(uploadFileAction({ base64: reader.result }))
          .then((response) => {
            setImagePreviewUrl(response?.data?.fileName);
          })
          .catch((error) => {
            console.error(error); // Handle error response
          });
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setImagePreviewUrl("");
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    const dataToSave = {};

    // dispatch(____Action(dataToSave))
    //   .then(() => {
    //     navigate(RoutesPaths.CATEGORY);
    //   })
    //   .finally(() => {
    //     setIsSubmitting(false);
    //   });
  };

  return (
    <>
      {/*begin::Main*/}
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        {/*begin::Content wrapper*/}
        <div className="d-flex flex-column flex-column-fluid">
          {/*begin::Toolbar*/}
          {/*begin::Content*/}
          <div id="kt_app_content" className="app-content flex-column-fluid">
            {/*begin::Content container*/}
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              {/*begin::Card*/}
              <div className="card">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Card title*/}
                  <div className="card-title fs-3 fw-bold">
                    Update/Register New Service Provider
                  </div>
                  {/*end::Card title*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Form*/}
                <form
                  id="kt_project_settings_form"
                  className="form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {/*begin::Card body*/}
                  <div className="card-body p-9">
                    {/*begin::Row*/}
                    <div className="row mb-5">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">Avatar</div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-lg-8">
                        {/*begin::Image input*/}
                        <div
                          className="image-input image-input-outline"
                          data-kt-image-input="true"
                        >
                          {/*begin::Preview existing avatar*/}
                          <div
                            className="image-input-wrapper w-125px h-125px bgi-position-center"
                            style={{
                              backgroundImage: `url("${
                                imagePreviewUrl
                                  ? `${backendUrl + imagePreviewUrl}`
                                  : "assets/media/svg/avatars/blank.svg"
                              }")`,
                            }}
                          />
                          {/*end::Preview existing avatar*/}
                          {/*begin::Label*/}
                          <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                            data-kt-image-input-action="change"
                            data-bs-toggle="tooltip"
                            title="Change avatar"
                          >
                            <i className="ki-outline ki-pencil fs-7" />
                            {/*begin::Inputs*/}
                            <input
                              type="file"
                              name="avatar"
                              accept=".png, .jpg, .jpeg"
                              onChange={handleImageChange}
                            />
                            <input
                              type="hidden"
                              name="avatar"
                              {...register("avatar")}
                            />
                            {/*end::Inputs*/}
                          </label>
                          {/*end::Label*/}
                          {/*begin::Cancel*/}
                          <span
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                            data-kt-image-input-action="cancel"
                            data-bs-toggle="tooltip"
                            title="Cancel avatar"
                          >
                            <i className="ki-outline ki-cross fs-2" />
                          </span>
                          {/*end::Cancel*/}
                          {/*begin::Remove*/}
                          {imagePreviewUrl && (
                            <span
                              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                              data-kt-image-input-action="remove"
                              data-bs-toggle="tooltip"
                              title="Remove avatar"
                              onClick={removeImage}
                            >
                              <i className="ki-outline ki-cross fs-2" />
                            </span>
                          )}
                          {/*end::Remove*/}
                        </div>
                        {/*end::Image input*/}
                        {/*begin::Hint*/}
                        <div className="form-text">
                          Allowed file types: png, jpg, jpeg.
                        </div>
                        {errors.image && (
                          <div className="text-danger">
                            {errors.image.message}
                          </div>
                        )}
                        {/*end::Hint*/}
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Full Name
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="name"
                          placeholder="Full name"
                          {...register("name", {
                            required: "Name is required.",
                          })}
                        />
                        {errors.name && (
                          <div className="text-danger">
                            {errors.name.message}
                          </div>
                        )}
                      </div>
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Company
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="company"
                          placeholder="Company"
                          {...register("company", {
                            required: "Company name is required.",
                          })}
                        />
                        {errors.company && (
                          <div className="text-danger">
                            {errors.company.message}
                          </div>
                        )}
                      </div>
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Contact Phone
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="phone"
                          placeholder="Contact phone number"
                          {...register("phone", {
                            required: "Phone number is required.",
                          })}
                        />
                        {errors.phone && (
                          <div className="text-danger">
                            {errors.phone.message}
                          </div>
                        )}
                      </div>
                      {/*begin::Col*/}
                    </div>
                    {/*end::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Company Site
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="site"
                          placeholder="Company site"
                          {...register("site", {
                            required: "Company site is required.",
                          })}
                        />
                        {errors.site && (
                          <div className="text-danger">
                            {errors.site.message}
                          </div>
                        )}
                      </div>
                      {/*begin::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*end::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Country
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="country"
                          placeholder="Country"
                          {...register("country", {
                            required: "Country name is required.",
                          })}
                        />
                        {errors.country && (
                          <div className="text-danger">
                            {errors.country.message}
                          </div>
                        )}
                      </div>
                      {/*begin::Col*/}
                    </div>
                    {/*end::Row*/}

                    {/*end::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Language *
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="language"
                          placeholder="Language"
                          {...register("language", {
                            required: "Language is required.",
                          })}
                        />
                        {errors.language && (
                          <div className="text-danger">
                            {errors.language.message}
                          </div>
                        )}
                        <span className="note">
                          Please select a preferred language, including date,
                          time, and number formating.
                        </span>
                      </div>
                      {/*begin::Col*/}
                    </div>
                    {/*end::Row*/}

                    {/*end::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Time Zone *
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="timeZone"
                          placeholder="Time zone"
                          {...register("timeZone", {
                            required: "Time zone is required.",
                          })}
                        />
                        {errors.timeZone && (
                          <div className="text-danger">
                            {errors.timeZone.message}
                          </div>
                        )}
                      </div>
                      {/*begin::Col*/}
                    </div>
                    {/*end::Row*/}

                    {/*end::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Currency *
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="currency"
                          placeholder="Currency"
                          {...register("currency", {
                            required: "Currency is required.",
                          })}
                        />
                        {errors.currency && (
                          <div className="text-danger">
                            {errors.currency.message}
                          </div>
                        )}
                      </div>
                      {/*begin::Col*/}
                    </div>
                    {/*end::Row*/}

                    {/*begin::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Communication
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9">
                        <div className="d-flex fw-semibold h-100">
                          <div className="form-check form-check-custom form-check-solid me-9">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="email"
                              name="comEmail"
                              checked={comEmail}
                              onChange={() => setComEmail(!comEmail)}
                            />
                            <label
                              className="form-check-label ms-3"
                              htmlFor="email"
                            >
                              Email
                            </label>
                          </div>
                          <div className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="phone"
                              name="comPhone"
                              checked={comPhone}
                              onChange={() => setComPhone(!comPhone)}
                            />
                            <label
                              className="form-check-label ms-3"
                              htmlFor="phone"
                            >
                              Phone
                            </label>
                          </div>
                        </div>
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Allow Marketing
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9">
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="status"
                            name="status"
                            // ref={register}
                            checked={status}
                            onChange={() => setStatus(!status)}
                          />
                          <label
                            className="form-check-label fw-semibold text-gray-500 ms-3"
                            htmlFor="status"
                          >
                            Active
                          </label>
                        </div>
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                  </div>
                  {/*end::Card body*/}
                  {/*begin::Card footer*/}
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <button
                      type="reset"
                      className="btn btn-light btn-active-light-primary me-2"
                      onClick={() => reset()}
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="kt_project_settings_submit"
                      disabled={isSubmitting || !isValid}
                    >
                      Save Changes
                    </button>
                  </div>
                  {/*end::Card footer*/}
                </form>
                {/*end:Form*/}
              </div>
              {/*end::Card*/}

              {/*begin::Card*/}
              <div className="card mt-20">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Card title*/}
                  <div className="card-title fs-3 fw-bold">
                    Upload Supporting Documents
                  </div>
                  {/*end::Card title*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Form*/}
                <form id="kt_project_settings_form" className="form">
                  {/*begin::Card body*/}
                  <div className="card-body p-9">
                    {/*begin::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <select
                          className="form-control form-control-solid"
                          name="name"
                        >
                          <option>Aadhar Card</option>
                        </select>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="file"
                          className="form-control form-control-solid"
                          name="name"
                        />
                      </div>
                    </div>
                    {/*end::Row*/}

                    {/*begin::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <select
                          className="form-control form-control-solid"
                          name="name"
                        >
                          <option>Pan Card</option>
                        </select>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="file"
                          className="form-control form-control-solid"
                          name="name"
                        />
                      </div>
                    </div>
                    {/*end::Row*/}

                    {/*begin::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <select
                          className="form-control form-control-solid"
                          name="name"
                        >
                          <option>Company Registeration Certificate</option>
                        </select>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="file"
                          className="form-control form-control-solid"
                          name="name"
                        />
                      </div>
                    </div>
                    {/*end::Row*/}
                  </div>
                  {/*end::Card body*/}
                  {/*begin::Card footer*/}
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <button
                      type="reset"
                      className="btn btn-light btn-active-light-primary me-2"
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="kt_project_settings_submit"
                    >
                      Upload
                    </button>
                  </div>
                  {/*end::Card footer*/}
                </form>
                {/*end:Form*/}
              </div>
              {/*end::Card*/}

              {/*begin::Card*/}
              <div className="card mt-20">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Card title*/}
                  <div className="card-title fs-3 fw-bold">
                    Sign-in Credentials
                  </div>
                  {/*end::Card title*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Form*/}
                <form id="kt_project_settings_form" className="form">
                  {/*begin::Card body*/}
                  <div className="card-body p-9">
                    {/*begin::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Login Email Id
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          name="name"
                          defaultValue="Email Id"
                        />
                      </div>
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Create Password
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="password"
                          className="form-control form-control-solid"
                          name="type"
                          defaultValue="**************"
                        />
                      </div>
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row mb-8">
                      {/*begin::Col*/}
                      <div className="col-xl-3">
                        <div className="fs-6 fw-semibold mt-2 mb-3">
                          Confirm Password *
                        </div>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-xl-9 fv-row">
                        <input
                          type="password"
                          className="form-control form-control-solid"
                          name="type"
                          defaultValue="**************"
                        />
                      </div>
                      {/*begin::Col*/}
                    </div>
                  </div>
                  {/*end::Card body*/}
                  {/*begin::Card footer*/}
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <button
                      type="reset"
                      className="btn btn-light btn-active-light-primary me-2"
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="kt_project_settings_submit"
                    >
                      Register
                    </button>
                  </div>
                  {/*end::Card footer*/}
                </form>
                {/*end:Form*/}
              </div>
              {/*end::Card*/}

              {/*begin::Card*/}
              <div className="card mt-20">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Card title*/}
                  <div className="card-title fs-3 fw-bold">Sign-in Method</div>
                  {/*end::Card title*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body py-4">
                  {/*begin::Table*/}
                  <table
                    className="table align-middle table-row-dashed fs-6 gy-5"
                    id="kt_table_users"
                  >
                    <tbody className="text-gray-600 fw-semibold">
                      <tr>
                        <td className="d-flex align-items-center">
                          {/*begin::User details*/}
                          <div className="d-flex flex-column">
                            <span className="text-gray-800 text-hover-primary mb-1">
                              Email Adress
                            </span>
                            <span>support@keenthemes.com</span>
                          </div>
                          {/*begin::User details*/}
                        </td>

                        <td className="text-end">
                          <span
                            className="btn btn-light btn-active-light-primary btn-flex btn-center btn-sm me-5"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                          >
                            Change Email
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="d-flex align-items-center">
                          {/*begin::User details*/}
                          <div className="d-flex flex-column">
                            <span className="text-gray-800 text-hover-primary mb-1">
                              Password
                            </span>
                            <span>****************</span>
                          </div>
                          {/*begin::User details*/}
                        </td>
                        <td className="text-end">
                          <span
                            className="btn btn-light btn-active-light-primary btn-flex btn-center btn-sm me-5"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                          >
                            Reset Password
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/*end::Table*/}
                </div>
                {/*end::Card*/}
              </div>
              {/*end::Card*/}
            </div>
            {/*end::Content container*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Content wrapper*/}
      </div>
      {/*end:::Main*/}
    </>
  );
}
