import axionIns from "../axios";

const addCategory = async (payload) => {
  return axionIns
    .post("admin/category", JSON.stringify(payload))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

const editCategory = async (payload) => {
  return axionIns
    .put("admin/category/" + payload._id, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

const categoryStatusAction = async (payload) => {
  return axionIns
    .put("admin/category/" + payload._id + "/" + payload.action)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("err", JSON.stringify(error.response.data));
      return error?.response?.data ? error?.response?.data : { status: false };
    });
};

const getCategory = async (page, limit, search) => {
  return axionIns
    .get(
      `admin/category?page=${page}&limit=${limit ? limit : 25}&search=${search}`
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  addCategory,
  editCategory,
  categoryStatusAction,
  getCategory,
};
